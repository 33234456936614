import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams, useLocation } from "react-router-dom";
import { useProductContext } from "../context/ProductContext";
import { CartContext } from "../context/cart";
import ProductBreadcrumb from "./ProductBreadcrumb";
import {Tab } from '@headlessui/react';

import { useUserContext } from "../context/userContext";
import SimilarProducts from "./SimilarProducts"; 
import { Helmet } from "react-helmet";

import ProductPDF from "../utils/pdfCreator";
import { PDFDownloadLink } from '@react-pdf/renderer';
import parse, { domToReact } from 'html-react-parser';
import DiscountNotification from "../components/DiscountNotification";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const ProductDetail = () => {
  const { cartItems, addToCart, removeFromCart } = useContext(CartContext);
  const { mainCategorySlug, inventoryNumber } = useParams(); 
  const [promoProduct, setPromoProduct] = useState(null); // För att lagra promo-datan
  const { discountedProducts, klippvaror, products, promotionalContent } = useProductContext(); 
  const { user } = useUserContext();
  const [poOrder, setPoOrder] = useState([]);
  const [product, setProduct] = useState(null);
  const [klippvara, setKlippvara] = useState(null);
  const [stockStatus, setStockStatus] = useState("");
  const [isCheckingAvailability, setIsCheckingAvailability] = useState(false);
  const location = useLocation(); // Hämta URL
  useEffect(() => {
    // Välj vilken datakälla som ska användas, beroende på om användaren är inloggad eller inte
    const availableProducts = discountedProducts.length > 0 ? discountedProducts : products;

    // Hitta den specifika produkten baserat på inventoryNumber
    const foundProduct = availableProducts.find((product) => product.inventoryNumber === inventoryNumber);
    setProduct(foundProduct);

    // Kolla om produkten finns i klippvaror
    if (foundProduct && klippvaror) {
      const matchingKlippvara = klippvaror.find(
        (klipp) => klipp.productData.inventoryNumber === foundProduct.inventoryNumber
      );

      if (matchingKlippvara) {
        setKlippvara(matchingKlippvara);
      }
    }

    // Kontrollera om produkten finns i någon kampanj (promotionalContent)
    if (promotionalContent && promotionalContent.length > 0) {
      promotionalContent.forEach((promo) => {
        const promoItem = promo.products.find((promoProduct) => promoProduct.inventoryNumber === inventoryNumber);
        if (promoItem) {
          setPromoProduct(promoItem); // Sätt promo-datan om matchning hittas
        }
      });
    }
  }, [discountedProducts, products, inventoryNumber, klippvaror, promotionalContent]);

 // Skydda mot att product kan vara null
 const salesCategory = product?.salesCategories && product.salesCategories.length > 0 ? product.salesCategories[0] : null;

 // Kontrollera om FRP-attributet finns i produkten eller i klippvarorData
 const frpAttribute = product?.attributes?.find(attribute => attribute.id === "FRP") ||
   klippvara?.attributes?.find(attribute => attribute.id === "FRP");
 const viktAttribute = product?.attributes?.find(attribute => attribute.id === "VIKT");

 const isDiscounted = product?.discountedPrice !== null && product?.discountedPrice !== undefined;

 const itemPrice = promoProduct
 ? promoProduct.discountType === 'percentage'
   ? product.defaultPrice * (1 - promoProduct.discountValue / 100) // Procentuell rabatt
   : promoProduct.discountType === 'fixed'
   ? product.defaultPrice - promoProduct.discountValue // Fast belopp rabatt
   : product.defaultPrice // Om inget kampanjvillkor gäller
 : isDiscounted && product.discountedPrice !== null && product.discountedPrice !== undefined && !isNaN(product.discountedPrice)
 ? product.discountedPrice // Rabatterat pris om tillgängligt och giltigt
 : product?.defaultPrice; // Ordinarie pris om ingen rabatt

 
 // Formatera priset
 const formattedPrice = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(itemPrice);
 // Dynamiskt tilldela klass baserat på om priset är rabatterat och discountedPrice är giltigt
const priceClass = (isDiscounted && product.discountedPrice !== null && product.discountedPrice !== undefined && !isNaN(product.discountedPrice)) || promoProduct
? 'text-red-700'
: 'text-gray-900 dark:text-white';

 
 const bestVara = product?.attributes?.find(attribute => attribute.id === "BEST" && attribute.value === "1");
 const frpValue = frpAttribute ? frpAttribute.value : null;
 const viktValue = viktAttribute ? viktAttribute.value : null;
 
 const incrementValue = frpValue ? parseInt(frpValue) : 1;
 const available = product?.warehouseDetails?.[0]?.quantityOnHand ?? 0;
console.log('available:', available);
 // Skydda mot att supplierDetails kan vara null eller undefined
 let supplierName = '';
 let supplierId = '';
 if (product?.supplierDetails && product.supplierDetails.length > 0) {
   supplierName = product.supplierDetails[0].supplierName;
   supplierId = product.supplierDetails[0].supplierId;
 }// Funktion för att beräkna lagerstatus
const getStockStatus = (available, incrementValue) => {
  if (available <= 0) {
    return "Slut i lager";
  } else if (available <= incrementValue * 3) {
    return `< ${incrementValue * 3} i lager`;
  } else {
    return "I lager";
  }
};
// Sätt lagerstatus baserat på tillgänglighet
useEffect(() => {
  if (product && available !== undefined) {
    const status = getStockStatus(available, incrementValue);
    console.log('stock status:', status);
    setStockStatus(status);
  }
}, [product, available, incrementValue]);

// Funktion för att kolla tillgänglighet via API
const checkAvailability = async () => {
  setIsCheckingAvailability(true); // Visa att vi kollar tillgänglighet
  try {
    const response = await axios.get(`/api/check-availability`, {
      params: { supplierId: supplierId,
        inventoryNumber: inventoryNumber
       }
    });
    console.log('response:', response.data);
    if (response.data.promisedOn) {
      setPoOrder(response.data.promisedOn); // Spara promisedOn-värdet
    } else if (response.data.message) {
      setPoOrder(response.data.message); // Visa meddelandet om ingen planerad inleverans hittas
    }
  
  } catch (error) {
    console.error('Error fetching order history:', error);
  } finally {
    setIsCheckingAvailability(false); // Ta bort laddningstillstånd
  }
};




  if (!product) {
    return <p>Laddar produkten...</p>; 
  }




console.log('klippvara data:', klippvara)

console.log('found product', product);
const handleAddToCart = (product, klippvara) => {
  const prefixedInventoryNumber = getPrefixedInventoryNumber(product, klippvara);

  // Kolla om produkten är en klippvara och använd klippvarupris om det finns
  let price = klippvara?.klippvarorData?.discountedPrice;

  // Om ingen klippvarupris finns, kolla om det finns en kampanj (promoProduct)
  if (!price && promoProduct) {
    price = promoProduct.discountType === 'percentage'
      ? product.defaultPrice * (1 - promoProduct.discountValue / 100) // Procentuell rabatt
      : product.defaultPrice - promoProduct.discountValue; // Fast belopp rabatt
  }

 // Om ingen kampanj finns, använd produktens discountedPrice eller defaultPrice
if (!price) {
  price = (product.discountedPrice !== null && product.discountedPrice !== undefined && !isNaN(product.discountedPrice))
    ? product.discountedPrice // Rabatterat pris om tillgängligt och giltigt
    : product.defaultPrice; // Ordinarie pris
}

  const productToAdd = { 
    ...product, 
    inventoryNumber: prefixedInventoryNumber, 
    price, // Skicka med rätt pris
  };

  // Lägg till i varukorgen med korrekt pris
  addToCart(productToAdd, klippvara?.klippvarorData?.lotSerialNumber, klippvara?.klippvarorData?.expirationDate);
};

const handleRemoveFromCart = (product, klippvara) => {
  const prefixedInventoryNumber = getPrefixedInventoryNumber(product, klippvara);
  removeFromCart({ ...product, inventoryNumber: prefixedInventoryNumber });
};

const getPrefixedInventoryNumber = (product, klippvara) => {
  if (klippvara) {
    return `${product.inventoryNumber}-KLIPP`;
  }
  return product.inventoryNumber;
};

const placeholderImageUrl = 'https://via.placeholder.com/250';

// Hämta första bilden som inte är en "web"-bild
const firstImageSrc = product.productImages && product.productImages.length > 0 
    ? product.productImages.find(image => !image.includes('/web/')) || placeholderImageUrl
    : placeholderImageUrl;

// Mappa ut alla bilder och videor (exkludera "/web/")
const mediaFiles = [
  ...(product.productImages?.filter(image => !image.includes('/web/')) || []),
  ...(product.productVideos || [])
];

 const productImageUrl = product.productImages && product.productImages.length > 0 ? product.productImages[0] : placeholderImageUrl;
//console.log('productImageUrl värdet på produkt:', productImageUrl);
// Initiera variablerna med tomma strängar eller null
let shortDescription = '';
let nutritionalValues = [];
let ingredients = '';
let foundFirstStrong = false;
  if (typeof product.body === 'string') {
  const parsedBody = parse(product.body, {
    replace: (domNode) => {
      if (!foundFirstStrong && domNode.type === 'text') {
        shortDescription += domNode.data;
      } else if (domNode.name === 'strong') {
        foundFirstStrong = true;
        const content = domNode.nextSibling && domNode.nextSibling.data ? domNode.nextSibling.data.trim() : "";
        if (domToReact(domNode.children).toString().includes('Näringsvärde per 100g:')) {
          const kcalIndex = content.indexOf('kcal') + 4; // 4 because we want to include "kcal" and the comma after it
          const firstValue = content.substring(0, kcalIndex).trim();
          const remainingContent = content.substring(kcalIndex).trim();
          const remainingValues = remainingContent.split(/(?<=g),/); // Split on commas followed by a non-digit
          if (remainingValues[0].startsWith(',')) {
            remainingValues[0] = remainingValues[0].substring(1).trim();
          }
          
          nutritionalValues = [
            { label: "Energi:", value: firstValue },
            { label: "Fett:", value: remainingValues[0] },
            { label: "- varav mättat fett:", value: remainingValues[1] },
            { label: "Kolhydrater:", value: remainingValues[2] },
            { label: "- varav sockerarter:", value: remainingValues[3] },
            { label: "Protein:", value: remainingValues[4] },
            { label: "Salt:", value: remainingValues[5] }
          ];
        } else if (domToReact(domNode.children).toString().includes('Ingredienser:')) {
          ingredients = content;
        }
      }
    }
  });
}



  return (
    <div className="bg-white dark:bg-gray-800 dark:text-white  pt-10 md:pt-20">
      <Helmet>
      {/* Dynamisk sidtitel */}
      <title>{product ? `${product.description} - Din Webshop` : "Laddar produkt..."}</title>

      {/* Dynamisk meta-beskrivning */}
      <meta name="description" content={product ? shortDescription : "Laddar produktinformation"} />

      {/* Open Graph-taggar för social delning */}
      <meta property="og:title" content={product ? `${product.description}` : "Laddar produkt..."} />
      <meta property="og:description" content={shortDescription || "Laddar produktbeskrivning"} />
      <meta property="og:image" content={firstImageSrc} />
      <meta property="og:type" content="product" />
      <meta property="og:url" content={`https://15101301.gastronomileverantoren.se${location.pathname}`} />

      {/* Twitter Card taggar */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={product ? `${product.description}` : "Laddar produkt..."} />
      <meta name="twitter:description" content={shortDescription || "Laddar produktbeskrivning"} />
      <meta name="twitter:image" content={firstImageSrc} />
    </Helmet>

    {/* Resten av komponentens JSX */}
      <DiscountNotification />  
      <div className="max-w-full px-4 sm:px-6 sm:py-8 lg:max-w-full lg:px-8 ">
        <ProductBreadcrumb product={product} mainCategorySlug={mainCategorySlug} />
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 mb-10">
        <Tab.Group as="div" className="flex flex-col-reverse">
  <div className="mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none">
    <Tab.List className="grid grid-cols-4 gap-6">
      {mediaFiles.length > 0 ? (
        mediaFiles.map((file, index) => (
          <Tab
            key={index}
            className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
          >
        {file.endsWith('.mp4') || file.endsWith('.webm') ? (
  <div className="relative w-full h-full">
    <video
      src={file}
      alt={`Video ${index}`}
      className="card-img h-full w-full object-cover object-center"
      muted
    />
    {/* Play-ikon overlay */}
    <div className="absolute inset-0 flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-12 h-12 text-white opacity-70"
      >
        <path d="M8 5v14l11-7z" />
      </svg>
    </div>
  </div>
) : (
              <img
                src={file}
                alt={`Image ${index}`}
                className="card-img h-full w-full object-contain object-center"
              />
            )}
          </Tab>
        ))
      ) : (
        <Tab className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4">
          <img
            src={placeholderImageUrl}
            alt="Placeholder Image"
            className="card-img h-full w-full object-cover object-center"
          />
        </Tab>
      )}
    </Tab.List>
  </div>

  <Tab.Panels className="aspect-h-1 aspect-w-1 w-full h-auto">
    {mediaFiles.length > 0 ? (
      mediaFiles.map((file, index) => (
        <Tab.Panel
          className="max-h-screen h-auto object-center justify-center flex"
          key={index}
        >
          {file.endsWith('.mp4') || file.endsWith('.webm') ? (
            <video
              src={file}
              controls
              className=" w-auto object-contain object-center sm:rounded-l"
            />
          ) : (
            <Zoom><img
              src={file}
              alt={`Image ${index}`}
              className="h-full max-h-96 w-auto object-contain object-center sm:rounded-l"
            /></Zoom>
          )}
        </Tab.Panel>
      ))
    ) : (
      <Tab.Panel>
        <img
          src={placeholderImageUrl}
          alt="Placeholder Image"
          className="h-full w-full object-cover object-center sm:rounded-l"
        />
      </Tab.Panel>
    )}
  </Tab.Panels>
</Tab.Group>

          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
            {bestVara && user && ( <span className="  inline-flex items-center rounded-md border-orange-200 border px-2 py-1 text-xs font-medium text-orange-900">
                     Beställningsvara
                  </span>)}
                  {promoProduct && (
          <p className="text-red-600">
            Kampanjpris! {promoProduct.discountType === 'percentage'
              ? `${promoProduct.discountValue}% rabatt`
              : `-${promoProduct.discountValue} kr`}
          </p>
        )}
        <p>{supplierName}</p>
              <h1 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white">{product.description}</h1>
              <div className="mt-3">
                <p>{product.inventoryNumber}- {viktValue} - {frpValue}</p>
                <h3 className="sr-only">Beskrivning:</h3>
               
                {product.body && (
                  <div className="space-y-6 mt-8  text-base text-gray-700 dark:text-white">
                    <span className="short-description space-y-6  text-lg text-gray-700 dark:text-white">{shortDescription}</span>
                   </div>)}
                   {user && (
  <div className="mt-6">
    <p className={`text-3xl font-semibold tracking-tight ${priceClass}`}>
          Pris: {formattedPrice}
        </p> {bestVara && ( <span className=" inline-flex items-center rounded-md  my-6 border border-orange-100 p-2 text-xs font-medium text-amber-800">
                    
      Vid beställning av beställningsvaror skapas en separat order då dessa varor inte finns i lager vid tillfället för din beställning. 
                      Vi återkommer när dessa varor beräknas vara i lager. OBS! Beställningsvaror räknas inte mot fraktfrinivån då de hamnar på separat order.
                  </span>)}
    <div className="stock text-md font-semibold text-gray-600 pt-2">
      Lagerstatus: {stockStatus}

      {/* Visa knappen endast om stockStatus är 'Slut i lager' */}
      {stockStatus === 'Slut i lager' && (
        <div className="mt-4">
          <button
            className="px-4 py-2 bg-indigo-500 text-white text-xs font-bold uppercase rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
            onClick={checkAvailability}
            disabled={isCheckingAvailability}
          >
            {isCheckingAvailability ? "Kontrollerar..." : "Kontrollera inleveransdatum"}
          </button>
          {poOrder.length !== 0 ? (<><p className="mt-2 text-sm text-gray-600">Uppskattat inleveransdatum: * </p>
           <p className="text-semibold"> {poOrder}</p>
            <p className="text-xs"> * observera att dessa datum kan komma att förändras med tiden</p></>) : ( null )}
        </div>
      )}
    </div>

    {/* Varukorgshantering */}
    <div className='mt-6 flex justify-between items-center'>
      {!cartItems.find(item => item.inventoryNumber === getPrefixedInventoryNumber(product, null)) ? (
        <button className='px-4 py-2 bg-gray-800 dark:bg-gray-600 text-white dark:text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700'
          onClick={() => handleAddToCart(product, null)}>
          Lägg i varukorg
        </button>
      ) : (
        <div className="flex gap-4">
          <button
            className="px-4 py-2 bg-red-400/75 text-white text-xs font-bold uppercase rounded hover:bg-red-700 focus:outline-none focus:bg-red-700"
            onClick={() => handleRemoveFromCart(product, null)}>
            -
          </button>
          <p className='text-gray-600'>{cartItems.find(item => item.inventoryNumber === getPrefixedInventoryNumber(product, null)).quantity}</p>
          <button
            className="px-4 py-2 bg-green-400/75 text-white text-xs font-bold uppercase rounded hover:bg-green-700 focus:outline-none focus:bg-green-700"
            onClick={() => handleAddToCart(product, null)}>
            +
          </button>
        </div>
      )}
    </div>
  </div>
)}
            {klippvara && user && (
              <div className="mt-8 border-t border-gray-200 dark:border-gray-600 pt-8">
                <h2 className="text-lg text-red-700 font-bold dark:text-white">Denna vara finns även som klippvara</h2>
                <p className="text-md font-semibold">Bäst före datum: {klippvara.klippvarorData.expirationDate}</p>
                <p className="text-lg font-bold">Pris: {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(klippvara.klippvarorData.discountedPrice)}</p>
                {!cartItems.find(item => item.inventoryNumber === getPrefixedInventoryNumber(product, klippvara.klippvarorData)) ? (
                  <button
                    className='px-4 py-2 bg-gray-800 dark:bg-gray-600 text-white dark:text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700'
                    onClick={() => handleAddToCart(product, klippvara)}>
                    Lägg i varukorg
                  </button>
                ) : (
                  <div className="flex gap-4">
                    <button
                      className="px-4 py-2 bg-red-400/75 text-white text-xs font-bold uppercase rounded hover:bg-red-700 focus:outline-none focus:bg-red-700"
                      onClick={() => handleRemoveFromCart(product, klippvara)}>
                      -
                    </button>
                    <p className='text-gray-600 dark:text-white'>
                      {cartItems.find(item => item.inventoryNumber === getPrefixedInventoryNumber(product, klippvara)).quantity}
                    </p>
                    <button
                      className="px-4 py-2 bg-green-400/75 text-white text-xs font-bold uppercase rounded hover:bg-green-700 focus:outline-none focus:bg-green-700"
                      onClick={() => handleAddToCart(product, klippvara)}>
                      +
                    </button>
                  </div>
                )}
              </div>
            )}
          
      
               <div className="mt-3 text-sm font-medium text-gray-900 dark:text-white">Ingår i kategori: <Link className="block prose prose-sm  text-gray-500 dark:text-white" to={`/cat/${salesCategory.slug}`}>
                {salesCategory.description}
              </Link></div>

                   {product.body && ( <><div className="ingredients order-2 mt-8 border-t border-gray-200 dark:border-gray-600 pt-8">
                      <div className="text-sm font-medium text-gray-900 dark:text-white">Ingredienser:</div> <div className="prose prose-sm mt-4 text-gray-500">{ingredients}</div>
                    </div>
                    <div className="nutritional-value order-3 w-fullmt-8 border-t border-gray-200 dark:border-gray-600 pt-8">
                      <div className="text-sm font-medium text-gray-900 dark:text-white"><strong>Näringsvärde per 100g:</strong></div>
                      <div className="w-full prose prose-sm mt-4 text-gray-500 dark:text-white">
                        {nutritionalValues.map((item, index) => (
                          <div className="nutritional-row flex justify-between border-b-2 p-2" key={index}>
                            <span className="label">{item.label}</span><span className="value">{item.value}</span>
                          </div>
                        ))}
                      </div>
                  
                      
                  </div>
                  </>       )}
              </div>
             
             
         
           
            <section aria-labelledby="policies-heading" className="mt-10">
              <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
                <div className="rounded-lg border border-gray-200 bg-gray-50 dark:bg-gray-600 p-6 text-center" >
                  <dt>
                  
                  <PDFDownloadLink className="" document={<ProductPDF product={product} productImageUrl={productImageUrl} />} fileName={`${product.description}.pdf`}>
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Ladda ned Produktblad')}
      </PDFDownloadLink>
                 </dt>
                  <dd className="mt-1 text-sm text-gray-500"></dd>
                </div>
              </dl>
            </section>
          </div>
        </div>
        {stockStatus === 'I lager' ? (
          <>
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Fler produkter från {supplierName}</h1>
            <SimilarProducts currentProduct={product} filterBySupplier />
          </>
        ) : (
          <>
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Produkter som liknar {product.description}</h1>
            <SimilarProducts currentProduct={product} />
          </>
        )}
      </div>
    </div>
  );
};

export default ProductDetail;