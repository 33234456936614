import React from 'react';
import Card from '../components/Card';
import { useProductContext } from '../context/ProductContext';


import SpecialBanner from '../components/SpecialBanner';

const Klippvaror = () => {
    const { klippvaror } = useProductContext();
    const selectedSalesCategorySlug = 'klippvaror';

   // Kontrollera om klippvaror är tom eller undefined
   if (!klippvaror || klippvaror.length === 0) {
    return null; // Rendera inget om klippvaror är tom
}

    return (
        <div className='p-8 mt-20'>
             <SpecialBanner slug={selectedSalesCategorySlug} />
            <h1 className="text-4xl font-extrabold dark:text-white">KLIPPVAROR:</h1>
            <section className="card-container  grid grid-cols-1 sm:grid-cols-2  md-grid-cols-3 lg:grid-cols-4 mt-10">
                {klippvaror.map((combinedItem) => (
                    <Card
                        key={`${combinedItem.productData.inventoryNumber}-KLIPP`}
                        klippvarorData={combinedItem.klippvarorData}
                        product={combinedItem.productData}
                    />
                ))}
            </section>
        </div>
    );
};

export default Klippvaror;