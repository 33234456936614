// searchUtils.js
import synonyms from '../db/synonyms.json'; // Importera synonymfilen

export const calculateMatchScore = (queryWords, text) => {
    if (!text) return 0;
    const lowerText = text.toLowerCase();
  
    let totalScore = 0;
  
    // För varje ord i sökqueryn, beräkna matchningspoäng
    queryWords.forEach(query => {
        const lowerQuery = query.toLowerCase();
        if (lowerText === lowerQuery) {
            // Om hela ordet matchar exakt, ge högsta poäng
            totalScore += 100;
        } else if (lowerText.includes(lowerQuery)) {
            // Beräkna matchningsprocent baserat på hur många tecken i texten som matchar ordet
            totalScore += lowerQuery.length / lowerText.length;
        }
    });
  
    return totalScore;
};

export const getSynonymMatches = (query) => {
    const lowerQuery = query.toLowerCase();
    if (synonyms[lowerQuery]) {
        return synonyms[lowerQuery];
    }
    return [];
};

export const searchProducts = (products, searchQuery) => {
    if (!searchQuery || !searchQuery.toLowerCase) return [];
    
    // Dela upp sökqueryn i flera ord
    const queryWords = searchQuery.split(" ");
  
    // Hämta synonymer för varje ord i sökqueryn
    const synonymMatches = queryWords.flatMap(getSynonymMatches);
    // Inkludera både sökfrågan och eventuella synonymer i sökningen
    const allQueries = [...queryWords, ...synonymMatches];
  
    const filteredProducts = products.map(product => {
        let priority = 0;
        let completenessScore = 0;
        let synonymScore = 0; // Lägg till poäng för synonymträffar
  
        // Loopa genom alla sökfrågor och beräkna matchning
        allQueries.forEach(query => {
            // Beräkna matchningspoäng för titel och artikelnummer
            const titleMatchScore = calculateMatchScore([query], product.title);
            const articleNumberMatchScore = calculateMatchScore([query], product.inventoryNumber);
            const descriptionMatchScore = calculateMatchScore([query], product.description);

            if (titleMatchScore > 0) priority += 50; // Prioriterad matchning på titel
            if (articleNumberMatchScore > 0) priority += 50; // Prioriterad matchning på artikelnummer
            if (descriptionMatchScore > 0) priority += 20; // Matchning på beskrivning
            completenessScore += titleMatchScore + articleNumberMatchScore + descriptionMatchScore;
  
            // Beräkna matchningspoäng för leverantör
            product.supplierDetails.forEach(supplier => {
                const supplierMatchScore = calculateMatchScore([query], supplier.supplierName);
                if (supplierMatchScore > 0) priority += 20; // Näst högsta prioritet: leverantör
                completenessScore += supplierMatchScore;
            });
  
            // Beräkna matchningspoäng för kategori
            product.salesCategories?.forEach(category => {
                const categoryMatchScore = calculateMatchScore([query], category.description);
                if (categoryMatchScore > 0) priority += 10; // Lägsta prioritet: kategori
                completenessScore += categoryMatchScore;
            });
  
            // Om en synonym används, öka synonympoängen
            if (synonymMatches.includes(query)) {
                synonymScore += 50; // Ge synonymer en högre poäng
            }
        });
  
        // Kombinera prioritet, kompletthetsfaktor och synonympoäng
        const finalScore = priority + completenessScore + synonymScore; 
  
        return { ...product, finalScore };
    });
  
    // Filtrera bort produkter utan giltig salesCategory slug och sortera på slutlig poäng
    return filteredProducts
        .filter(product => product.salesCategories && product.salesCategories.length > 0 && product.salesCategories[0].slug)
        .sort((a, b) => b.finalScore - a.finalScore);
};
