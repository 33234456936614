import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../context/cart';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { sendOrder } from '../auth';
import { decryptData, encryptData } from '../services/cryptoUtils'; // Importera dekrypteringsfunktionen

const CheckoutPage = () => {
  const { 
    cartItems, 
    addToCart, 
    removeFromCart, 
    clearCart, 
    getCartTotal, 
    campaignDiscount, 
    isCombinedDelivery, 
    setIsCombinedDelivery,
    getBestItemsTotal, 
    getNonBestItemsTotal,
    getTotal,
    getShippingStatus 
  } = useContext(CartContext);

   const [isLoading, setIsLoading] = useState(true);
  const [orderIsLoading, setOrderIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false); // För att förhindra dubletter
  const [orderData, setOrderData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState(userData?.deliveryAddress || {});
  const [noteValue, setNoteValue] = useState('');
  const [deliveryDateValue, setDeliveryDateValue] = useState('');
  const [progress, setProgress] = useState(0); // Progress state
  const navigate = useNavigate();
  let location = useLocation(); // För att spåra aktuell URL
    // Kontrollera om data är giltig JSON
    const isValidJson = (data) => {
      try {
        JSON.parse(data);
        return true;
      } catch (e) {
        return false;
      }
    };
  
    // Använd useEffect för att hantera användardata när komponenten monteras
    useEffect(() => {
      const storedUserData = sessionStorage.getItem('userData');
  
      if (storedUserData && isValidJson(storedUserData)) {
        try {
          // Kontrollera om datan redan är dekrypterad (saknar isEncrypted-flaggan)
          const parsedData = JSON.parse(storedUserData);
          if (parsedData.isEncrypted) {
            // Om datan är krypterad, dekryptera den
            const decryptedData = decryptData(storedUserData);
            setUserData(decryptedData); // Sätt användardata
            setDeliveryAddress(decryptedData.deliveryAddress || {}); // Sätt leveransadress
          } else {
            // Om datan redan är dekrypterad, använd den direkt
            setUserData(parsedData);
            setDeliveryAddress(parsedData.deliveryAddress || {});
          }
        } catch (error) {
          console.error("Fel vid dekryptering eller användardatahantering:", error.message);
        }
      } else {
        console.error("Ingen eller ogiltig användardata i sessionStorage.");
      }
  
      setIsLoading(false); // Sluta ladda efter att ha försökt hantera datan
    }, []);

  
  // Cleanup-funktion för att kryptera data när komponenten avmonteras
  useEffect(() => {
    return () => {
      if (userData) {
        const encryptedUserData = encryptData(userData);
        sessionStorage.setItem('userData', encryptedUserData); // Kryptera och spara data när komponenten avmonteras
      }
    };
  }, [userData]); // Lägg till userData som beroende så vi hanterar förändringar i data

  useEffect(() => {
    // Om `userData` redan är laddad (kan behövas för framtida användning)
    if (userData) {
      console.log('User data:', userData);
    }
  }, [userData]);

 
  // Använd korrekt total för fraktfri-beräkning beroende på om samleverans är aktiv
  const cartTotalForShipping = isCombinedDelivery ? getTotal() : getNonBestItemsTotal();
  const { amountRemaining, percentage, isFreeShipping } = getShippingStatus(cartTotalForShipping);

  const formattedAmountRemaining = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(amountRemaining);

  // Filter for "BEST" items
  const bestItems = cartItems.filter(item =>
    item.attributes.some(attribute => attribute.id === "BEST" && attribute.value === "1")
  );


// Filter for non-"BEST" items (inklusive de som inte har "BEST" attributet alls)
const nonBestItems = cartItems.filter(item =>
  item.attributes.some(attribute => attribute.id === "BEST" && attribute.value === "0") ||
  !item.attributes.some(attribute => attribute.id === "BEST")
);

  useEffect(() => {
    const storedUserData = sessionStorage.getItem('userData');
    if (storedUserData) {
      const decryptedData = decryptData(storedUserData);
      setUserData(decryptedData);
      setDeliveryAddress(decryptedData.deliveryAddress || {});
    }
    setIsLoading(false);
  }, []);


  // Progress bar logic
  useEffect(() => {
    let interval = null; // Declare interval outside of useEffect to manage its scope

    if (orderIsLoading) {
      console.log("Progress bar started, initial progress:", progress);
      
      // Start progress increment
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 95) {
            clearInterval(interval); // Stop incrementing at 95%
            return prevProgress;
          }
          return prevProgress + 1; // Increment progress by 1
        });
      }, 200); // Increment every 200ms
    }

    return () => {
      if (interval) {
        clearInterval(interval); // Ensure the interval is cleared on component unmount
      }
    };
  }, [orderIsLoading]); // Trigger effect when orderIsLoading changes

  useEffect(() => {
    console.log('Progress:', progress);  // Log progress value
  }, [progress]);

  useEffect(() => {
    if (userData) {
      setIsLoading(false);
      sessionStorage.setItem('userData', JSON.stringify(userData));
    }
  }, [userData]);


  
  const handleNoteChange = (e) => {
    const additionalInfo = deliveryAddressChanged() ? 'OBS - Avvikande leveransadress\n' : '';
    console.log('Additional Info:', additionalInfo);
    console.log('Note Value:', e.target.value);
    setNoteValue(additionalInfo + e.target.value);
  };

 
  // Funktion för att kontrollera om en dag är en helg
  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6; // Söndag = 0, Lördag = 6
  };

  // Funktion för att hitta nästa tillgängliga vardag minst två dagar framåt
  const getNextAvailableDate = () => {
    let date = new Date(); // Börja med dagens datum
    let addedDays = 0;

    // Lägg till två dagar framåt, exklusive helger
    while (addedDays < 2) {
      date.setDate(date.getDate() + 1);
      if (!isWeekend(date)) {
        addedDays++;
      }
    }

    return date;
  };

  // Funktion för att formatera datum till ÅÅÅÅ-MM-DD
  const formatDateToYYYYMMDD = (date) => {
    return date.toISOString().split('T')[0]; // Ger ÅÅÅÅ-MM-DD
  };

  // Sätt standardvärde för leveransdatum till två dagar framåt
  useEffect(() => {
    const defaultDeliveryDate = getNextAvailableDate();
    setDeliveryDateValue(formatDateToYYYYMMDD(defaultDeliveryDate));
  }, []);

  // Hantering av ändringar i datumfältet
  const handleDeliveryDateChange = (event) => {
    setDeliveryDateValue(event.target.value);
  };

  const deliveryAddressChanged = () => {
    if (deliveryAddress && userData && userData.deliveryAddress) {
      const { addressLine1, addressLine2, addressLine3, postalCode, city } = deliveryAddress;
      const { addressLine1: originalAddressLine1, addressLine2: originalAddressLine2, addressLine3: originalAddressLine3, postalCode: originalPostalCode, city: originalCity } = userData.deliveryAddress;
      const addressChanged = addressLine1 !== originalAddressLine1 || addressLine2 !== originalAddressLine2 || addressLine3 !== originalAddressLine3 || postalCode !== originalPostalCode || city !== originalCity;
      
      console.log('Address changed:', addressChanged);
      return addressChanged;
    }
    return false;
  };

  const handleSaveChanges = () => {
    setOrderData({
      ...orderData,
      soShippingAddress: {
        value: {
          overrideAddress: { value: true },
          addressLine1: { value: deliveryAddress.addressLine1 },
          addressLine2: { value: deliveryAddress.addressLine2 },
          addressLine3: { value: deliveryAddress.addressLine3 },
          postalCode: { value: deliveryAddress.postalCode },
          city: { value: deliveryAddress.city }
        }
      }
    });
    setIsEditing(false);
  };
  const handleSendOrder = async () => {
    try {
      console.log("Setting orderIsLoading to true");
      setIsDisabled(true); // Inaktivera knappen så man inte kan klicka flera gånger
      setOrderIsLoading(true);  // Visa laddningsindikator
  
      const additionalInfo = deliveryAddressChanged() ? 'OBS - Avvikande leveransadress --' : '';
      const updatedNoteValue = additionalInfo + noteValue;
  
      // buildOrderData tar nu ett extra argument för att sätta rätt description
      const buildOrderData = (items, isBestItems = false) => {
        return {
          requestOn: {
            value: deliveryDateValue
          },
          soShippingAddress: {
            value: {
              overrideAddress: { value: true },
              addressLine1: { value: deliveryAddress.addressLine1 },
              addressLine2: { value: deliveryAddress.addressLine2 },
              addressLine3: { value: deliveryAddress.addressLine3 },
              postalCode: { value: deliveryAddress.postalCode },
              city: { value: deliveryAddress.city }
            }
          },
          lines: items.map(item => {
            const isClipItem = item.lotSerialNumber && item.expirationDate;
            const priceValue = item.defaultPrice;  // Använd alltid ordinarie pris här
            const cleanedInventoryNumber = item.inventoryNumber.replace(/-KLIPP$/, '');
      
            let lineItem = {
              salesOrderOperation: { value: "Issue" },
              shipComplete: { value: "CancelRemainder" },
              operation: "Insert",
              inventoryNumber: { value: cleanedInventoryNumber },
              warehouse: { value: "1" },
              uom: { value: "ST" },
              quantity: { value: item.quantity },
              itemUrl: { value: item.imageUrl },
              itemDescription: { value: item.description },
              price: { value: priceValue },  // Ordinarie pris här
              unitPrice: { value: priceValue },  // Ordinarie pris här
             discountAmount: {value: 0},  // Nollställ discountAmount
             discountPercentage: {value: 0},  // Nollställ discountPercentage
              ...(isClipItem && {
                note: {
                  value: `KLIPPVARA BF: ${item.expirationDate} BATCH: ${item.lotSerialNumber}`
                },
                unitPrice: {
                  value: priceValue // Hantera unitPrice för KLIPP-varor
                }
              })
            };


        // Kontrollera om det är en kampanjvara med discountType
    // Kontrollera om det är en kampanjvara med discountType
    if (item.discountType) {
      if (item.discountType === "percentage") {
        // Beräkna mellanskillnaden mellan unitPrice och discountedPrice och multiplicera med quantity
        const discountDifference = priceValue - parseFloat(item.discountedPrice);
        lineItem.discountAmount = { value: parseFloat(discountDifference * item.quantity) };
      // lineItem.discountPercent = { value: parseFloat(item.discountValue).toFixed(6) };  // Sätt procentsats i rätt format
      } else if (item.discountType === "fixed") {
        // Multiplicera discountValue med quantity och sätt i discountAmount
        lineItem.discountAmount = { value: parseFloat(item.discountValue * item.quantity)};
      }
    }

    return lineItem;
  }),
          orderType: { value: "SO" },
          hold: { value: true },
          customer: { value: userData.number },
          description: { value: isBestItems ? "BESTÄLLNINGSVAROR" : updatedNoteValue },  // Sätter rätt description
        };
      };

      let combinedOrder = null;
      let nonBestItemsOrder = null;
      let bestItemsOrder = null;
  
      if (isCombinedDelivery) {
        const combinedItems = [...bestItems, ...nonBestItems];
        combinedOrder = buildOrderData(combinedItems); // Kombinerad order
        await sendOrder(combinedOrder);  // Skicka kombinerad order
        console.log('Kombinerad order:', combinedOrder);
      } else {
        // Skicka separata beställningar
        if (nonBestItems.length > 0) {
          nonBestItemsOrder = buildOrderData(nonBestItems);  // Ordinarie varor
          await sendOrder(nonBestItemsOrder);
          console.log('Non best items order', nonBestItemsOrder);
        }
        if (bestItems.length > 0) {
          bestItemsOrder = buildOrderData(bestItems, true);  // Beställningsvaror
          await sendOrder(bestItemsOrder);
          console.log('Best items order', bestItemsOrder);
        }
      }
  
      // När servern svarar, sätt progress till 100%
      setProgress(100);
  
      // Avsluta laddningsindikatorn efter ordern är skickad
      setTimeout(() => {
        setOrderIsLoading(false);
      }, 500);
  
      clearCart();
      
    // Skicka användaren till order-success med orderdata
    navigate('order-success', {
      state: {
        combinedOrder,
        nonBestItemsOrder,
        bestItemsOrder,
      }
    });
    } catch (error) {
      console.error('Fel vid skicka beställning:', error.message);
    } finally {
      setOrderIsLoading(false);  // Sluta visa laddningsindikatorn
      setIsDisabled(false);      // Återaktivera knappen när processen är klar
    }
  };
  
  

  const placeholderImageUrl = 'https://via.placeholder.com/350';
 

  return (
    <div className="bg-gray-50 dark:bg-gray-800 ">
   {  }
 { orderIsLoading && (
        <div className="loading-overlay w-full h-screen">
          <div className="loading-indicator text-center pt-20">
            <div className="spinner text-center mx-auto"> <svg aria-hidden="true" class="w-20 mx-auto my-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg></div> 
            <h3 className='text-4xl font-bold text-center'>Behandlar din order...</h3>
            <div className="mx-auto my-8 progress-bar w-1/3 h-5 bg-gray-200 rounded">
              <div
                className="progress-bar-inner bg-emerald-500 h-5 transition-all	 ease-in-out	"
                style={{ width: `${progress}%` }} // Progress-bredd
              ></div>   
          </div>
          </div>
        </div>
)}

        <div className={`mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8 ${orderIsLoading ? 'hidden' : ''}`}>
        <h1 className='text-4xl mt-10 text-bold dark:text-white'>Kassan:</h1>
        <h2 className="sr-only dark:text-white">Checkout</h2>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <form className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16"
            onSubmit={(e) => {
              e.preventDefault();
            }}>
            <div>
              <div>
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">Dina uppgifter:</h2>
                <div className="mt-4">
                  <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 dark:text-white">E-postadress:</label>
                  <div className="mt-1">
                    <input
                      type="email"
                      id="email-address"
                      name="email-address"
                      autoComplete="email"
                      disabled
                      value={userData.mainContact.email}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                    />
                  </div>
                  <div>
                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 dark:text-white">Kundnummer:</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="first-name"
                        value={userData.number}
                        name="first-name"
                        autoComplete="given-name"
                        disabled
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-white">Telefonnummer:</label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      value={userData.mainContact.phone1}
                      autoComplete="tel"
                      disabled
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-10 border-t border-gray-200 pt-10">
                <h2 className="text-lg font-medium text-gray-900 dark:text-white">Leveransadress:</h2>
                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  <div className="sm:col-span-2">
                    <label htmlFor="company" className="block text-sm font-medium text-gray-700 dark:text-white">Företagsnamn</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="company"
                        value={userData.mainContact.name}
                        disabled
                        id="company"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 dark:text-white">Referens:</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="last-name"
                        name="last-name"
                        autoComplete="family-name"
                        disabled={!isEditing}
                        value={deliveryAddress.addressLine1}
                        onChange={(e) => setDeliveryAddress({ ...deliveryAddress, addressLine1: e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700 dark:text-white">Adressrad 1:</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={deliveryAddress.addressLine1}
                        disabled={!isEditing}
                        onChange={(e) => setDeliveryAddress({ ...deliveryAddress, addressLine1: e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700 dark:text-white">Adressrad 2:</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={deliveryAddress.addressLine2}
                        disabled={!isEditing}
                        onChange={(e) => setDeliveryAddress({ ...deliveryAddress, addressLine2: e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700 dark:text-white">Adressrad 3:</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={deliveryAddress.addressLine3}
                        disabled={!isEditing}
                        onChange={(e) => setDeliveryAddress({ ...deliveryAddress, addressLine3: e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="city" className="block text-sm font-medium text-gray-700 dark:text-white">Stad:</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="city"
                        name="city"
                        autoComplete="address-level2"
                        disabled={!isEditing}
                        value={deliveryAddress.city}
                        onChange={(e) => setDeliveryAddress({ ...deliveryAddress, city: e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700 dark:text-white">Postnummer:</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="postal-code"
                        name="postal-code"
                        autoComplete="postal-code"
                        disabled={!isEditing}
                        value={deliveryAddress.postalCode}
                        onChange={(e) => setDeliveryAddress({ ...deliveryAddress, postalCode: e.target.value })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-6">
                  <h3 className="text-sm font-medium text-gray-900 dark:text-white">Avvikande leveransadress?</h3>
                  <p className="text-sm text-gray-600 mt-1 dark:text-gray-400" >Sätt en avvikande leveransadress för denna beställning. Observera att du endast ändrar leveransadressen för denna order och att detta inte uppdaterar din leveransadress permanent. Vill du göra detta istället ber vi dig göra en anteckning i meddelandefältet nedan eller kontakta oss.</p>
                  <button
                    type="button"
                    onClick={() => {
                      if (isEditing) handleSaveChanges();
                      setIsEditing(!isEditing);
                    }}
                    className="mt-3 w-full bg-green-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                  >
                    {isEditing ? 'Spara ändringar' : 'Redigera adress'}
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
  <div>
    <h2 className="text-lg font-medium text-gray-900 dark:text-white">Orderöversikt</h2>
              {/* Delivery date input */}
  <div className="mt-10">
    <label htmlFor="deliverydate" className="block text-sm font-medium text-gray-700 dark:text-white">
      Önskat leveransdatum för denna order:
    </label>
    <div className="mt-1">
      <input
        type="date"
        id="deliverydate"
        name="deliverydate"
        min={deliveryDateValue}
        value={deliveryDateValue}
        onChange={handleDeliveryDateChange}
        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
      />
    </div>
  </div>
    {/* Om samleverans är vald, rendera alla produkter i en lista */}
    {isCombinedDelivery ? (
      <div className="mt-4 bg-white border border-gray-200 dark:border-gray-600 rounded-lg shadow-sm dark:bg-gray-700 dark:text-white">
        <h3 className="sr-only">Items in your cart</h3>
        <ul className="divide-y divide-gray-200 dark:divide-gray-600">
          {[...nonBestItems, ...bestItems].map((item) => {
            const productImageUrl = item.productImages && item.productImages.length > 0 ? item.productImages[0] : placeholderImageUrl;
            const itemPrice = item.discountedPrice !== null && item.discountedPrice !== undefined ? item.discountedPrice : item.defaultPrice;

            return (
              <li key={item.inventoryNumber} className="flex relative py-6 px-4 sm:px-6">
                <div className="flex-shrink-0">
                  <img
                    src={productImageUrl}
                    alt={item.imageAlt}
                    className="w-12 object-contain h-12 rounded-md"
                  />
                </div>
                <div className="ml-6 flex-1 flex flex-col justify-between">
                  <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0 md:flex">
                    <div className="flex justify-between w-full">
                      <h3 className="text-sm w-full">
                        <Link
                          to={`/cat/${item.salesCategories[0].slug}/product/${item.inventoryNumber}`}
                          className="font-medium text-gray-700 dark:text-white hover:text-gray-800"
                        >
                          {item.description}
                        </Link>
                      </h3>
                      <p className="ml-4 text-sm font-medium">
                        {new Intl.NumberFormat('sv-SE', {
                          style: 'currency',
                          currency: 'SEK',
                        }).format(itemPrice)}
                      </p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 sm:mt-0 sm:ml-6 md:ml-0 md:mt-2">
                    <div className="relative inline-block text-left">
                      <div className="flex items-center space-x-2">
                        <button
                          type="button"
                          className="px-4 py-2 bg-red-400/70 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                          onClick={() => removeFromCart(item)}
                        >
                          -
                        </button>
                        <span className="text-sm font-medium text-gray-900 dark:text-white">
                          {item.quantity}
                        </span>
                        <button
                          type="button"
                          className="px-4 py-2 bg-green-400/70 text-white text-xs font-bold uppercase rounded hover:bg-green-400/65 focus:outline-none focus:bg-green-400/45"
                          onClick={() => addToCart(item)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    ) : (
      <>
        {/* Render non-"BEST" items separat om samleverans inte är vald */}

  <div className='mt-10'>
  <h2 className="text-lg font-medium text-gray-900  dark:text-white">Order:</h2>
          
        
        <div className=" bg-white border border-gray-200 dark:border-gray-600 rounded-lg shadow-sm dark:bg-gray-700 dark:text-white">
          <h3 className="sr-only">Items in your cart</h3>
        
          <ul className="divide-y divide-gray-200 dark:divide-gray-600">
            {nonBestItems.map((item) => {
              const productImageUrl = item.productImages && item.productImages.length > 0 ? item.productImages[0] : placeholderImageUrl;
              const itemPrice = item.discountedPrice !== null && item.discountedPrice !== undefined ? item.discountedPrice : item.defaultPrice;

              return (
                <li key={item.inventoryNumber} className="flex relative py-6 px-4 sm:px-6">
                  <div className="flex-shrink-0">
                    <img
                      src={productImageUrl}
                      alt={item.imageAlt}
                      className="w-12 object-contain h-12 rounded-md"
                    />
                  </div>
                  <div className="ml-6 flex-1 flex flex-col justify-between">
                    <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0 md:flex">
                      <div className="flex justify-between w-full">
                        <h3 className="text-sm w-full">
                          <Link
                            to={`/cat/${item.salesCategories[0].slug}/product/${item.inventoryNumber}`}
                            className="font-medium text-gray-700 dark:text-white hover:text-gray-800"
                          >
                            {item.description}
                          </Link>
                        </h3>
                        <p className="ml-4 text-sm font-medium">
                          {new Intl.NumberFormat('sv-SE', {
                            style: 'currency',
                            currency: 'SEK',
                          }).format(itemPrice)}
                        </p>
                      </div>
                    </div>
                    <div className="flex-shrink-0 sm:mt-0 sm:ml-6 md:ml-0 md:mt-2">
                    <div className="relative inline-block text-left">
                      <div className="flex items-center space-x-2">
                        <button
                          type="button"
                          className="px-4 py-2 bg-red-400/70 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                          onClick={() => removeFromCart(item)}
                        >
                          -
                        </button>
                        <span className="text-sm font-medium text-gray-900 dark:text-white">
                          {item.quantity}
                        </span>
                        <button
                          type="button"
                          className="px-4 py-2 bg-green-400/70 text-white text-xs font-bold uppercase rounded hover:bg-green-400/65 focus:outline-none focus:bg-green-400/45"
                          onClick={() => addToCart(item)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        </div>

        {/* Render "BEST" items separat om samleverans inte är vald */}
        {bestItems.length > 0 && (
          <div className="mt-10">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white">Beställningsvaror</h2>
            <p className='text-sm text-gray-600'>(Vi meddelar när beställningsvaror kommer in i lager.)</p>
            <div className="mt-4 bg-white border border-gray-200 dark:border-gray-600 rounded-lg shadow-sm dark:bg-gray-700 dark:text-white">
              <h3 className="sr-only">Items in your cart</h3>
              <ul className="divide-y divide-gray-200 dark:divide-gray-600">
                {bestItems.map((item) => {
                  const productImageUrl = item.productImages && item.productImages.length > 0 ? item.productImages[0] : placeholderImageUrl;
                  const itemPrice = item.discountedPrice !== null && item.discountedPrice !== undefined ? item.discountedPrice : item.defaultPrice;

                  return (
                    <li key={item.inventoryNumber} className="flex relative py-6 px-4 sm:px-6">
                      <div className="flex-shrink-0">
                        <img
                          src={productImageUrl}
                          alt={item.imageAlt}
                          className="w-12 object-contain h-12 rounded-md"
                        />
                      </div>
                      <div className="ml-6 flex-1 flex flex-col justify-between">
                        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0 md:flex">
                          <div className="flex justify-between w-full">
                            <h3 className="text-sm w-full">
                              <Link
                                to={`/cat/${item.salesCategories[0].slug}/product/${item.inventoryNumber}`}
                                className="font-medium text-gray-700 dark:text-white hover:text-gray-800"
                              >
                                {item.description}
                              </Link>
                            </h3>
                            <p className="ml-4 text-sm font-medium">
                              {new Intl.NumberFormat('sv-SE', {
                                style: 'currency',
                                currency: 'SEK',
                              }).format(itemPrice)}
                            </p>
                          </div>
                          
                          
                     
                        </div>
                        <div className="flex-shrink-0 sm:mt-0 sm:ml-6 md:ml-0 md:mt-2">
                    <div className="relative inline-block text-left">
                      <div className="flex items-center space-x-2">
                        <button
                          type="button"
                          className="px-4 py-2 bg-red-400/70 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                          onClick={() => removeFromCart(item)}
                        >
                          -
                        </button>
                        <span className="text-sm font-medium text-gray-900 dark:text-white">
                          {item.quantity}
                        </span>
                        <button
                          type="button"
                          className="px-4 py-2 bg-green-400/70 text-white text-xs font-bold uppercase rounded hover:bg-green-400/65 focus:outline-none focus:bg-green-400/45"
                          onClick={() => addToCart(item)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </>
    )}
  </div>
{/* Checkbox for combined delivery */}
{(bestItems.length > 0 || isCombinedDelivery) && (
  <div className="mt-10">
    <div className="flex items-center mt-6 my-6">
      <input
        type="checkbox"
        checked={isCombinedDelivery}
        onChange={(e) => setIsCombinedDelivery(e.target.checked)}
      />
      <label htmlFor="combinedDelivery" className="ml-3 block text-sm text-gray-900 dark:text-white">
        Önskar samleverans?
      </label>
    </div>
  </div>
)}


  <div className="mt-10">
                <label htmlFor="note" className="block text-sm font-medium text-gray-700 dark:text-white">
                  Meddelande till säljare
                </label>
                <div className="mt-1">
                  <textarea
                    id="note"
                    name="note"
                    rows={4}
                    value={noteValue}
                    onChange={handleNoteChange}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-white/5 dark:text-white dark:border-gray-600"
                  />
                </div>
              </div>
              <div className="mt-6">
  {/* Visa subtotal och rabatter */}
  {campaignDiscount > 0 && (
    <>
      <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
        <p>Subtotal:</p>
        <p>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(campaignDiscount + (isCombinedDelivery ? getTotal() : getNonBestItemsTotal()))}</p>
      </div>
      <div className="flex justify-between text-base font-medium py-1 font-medium text-red-700 dark:text-white">
        <p>Rabatter:</p>
        <p>-{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(campaignDiscount)}</p>
      </div>
    </>
  )}

  {/* Hantera olika fall för totalberäkning */}
  {!isCombinedDelivery ? (
    <div>
      {/* Visa separat total för vanliga varor */}
      <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
        <span>Order Total:</span>
        <span>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(getNonBestItemsTotal())}</span>
      </div>
      {/* Visa separat total för beställningsvaror om sådana finns */}
      {bestItems.length > 0 && (
        <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white mt-4">
          <span>Beställningsorder Total:</span>
          <span>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(getBestItemsTotal())}</span>
        </div>
      )}
    </div>
  ) : (
    <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
      {/* Visa sammanslagen total */}
      <span>Total:</span>
      <span>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(getTotal())}</span>
    </div>
  )}

  {/* Visa fraktfritt eller hur mycket som återstår till fraktfritt */}
  <div className="mt-4">
    <p className={isFreeShipping ? 'inline-flex items-center rounded-md bg-green-100 px-1.5 py-1 font-bold text-xs font-bold text-green-700 ' : 'dark:text-white  px-1.5 py-0.5 text-xs font-medium'}>
      {isFreeShipping ? 'FRAKTFRITT!' : `Kvar till fraktfritt: ${formattedAmountRemaining}`}
    </p>

    {/* Visa fraktfritt-progressbar om frakt inte är fri */}
    {!isFreeShipping && (
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div className="bg-red-600 h-2.5 rounded-full transition-all ease-in-out" style={{ width: `${percentage}%` }}></div>
      </div>
    )}
  </div>
</div>
              <div className="mt-6">
                <button
                  type="submit"
                  onClick={handleSendOrder}
                  disabled={isDisabled}
                  className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Skicka beställning
                </button>
              </div>
           
</div>
              
          </form>
        )}
      </div>
    </div>
  );
};

export default CheckoutPage;