import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useProductContext } from '../../context/ProductContext';
import { AiOutlineRight } from 'react-icons/ai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgePercent} from '@awesome.me/kit-e012a9aa4e/icons/classic/solid'

function SalesCategory() {
  const navigate = useNavigate();
  const { discounts = [], promotionalContent = [], loading } = useProductContext(); // Lyssnar på promotionalContent i ProductContext

  if (loading) {
    return <p>Laddar...</p>;
  }

  // Filtrera kampanjer från affärssystemet
  const promotionalDiscounts = discounts.filter(discount => discount.promotional && discount.items && discount.items.length > 0 && discount.active);

  // Hantera klick på kampanjer från affärssystemet
  const handleDiscountClick = (discountCode) => {
    navigate(`/kampanj/${discountCode}`);
  };

  // Hantera klick för promotionalContent-kampanjer (baserade på slug)
  const handlePromoClick = (slug) => {
    navigate(`/kampanjer/${slug}`);
  };


  return (
    <nav className="flex flex-1 flex-col dark:text-white" aria-label="Sidebar">
      {(promotionalDiscounts.length > 0 || promotionalContent.length > 0) && (
        <>
          <h2 className="sidebar-title m-3 font-bold">Aktiva kampanjer</h2>
          <ul className="space-y-2 font-medium mb-8">
            {/* Rendera kampanjer från affärssystemet */}
            {promotionalDiscounts.map((discount, index) => (
              <li key={index} className="border-b-2 dark:border-b-gray-600">
                <button
                  className="sidebar-label-container flex items-center w-full justify-between text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  onClick={() => handleDiscountClick(discount.discountCode)}
                >
                  <span className="ms-3">{discount.description}</span>
                </button>
              </li>
            ))}
            
            {/* Rendera kampanjer från promotionalContent */}
            {promotionalContent.map((promo, index) => (
              <li key={index} className="border-b-2 dark:border-b-gray-600">
                <button
                  className="sidebar-label-container ms-3 flex w-full align-center items-center text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  onClick={() => handlePromoClick(promo.slug)}
                >
                  <FontAwesomeIcon icon={faBadgePercent} className="text-red-800 mr-3" />
                  <span>{promo.title}</span> {/* Visa titeln från promotionalContent */}
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
      <div className="border-b-2 dark:border-b-gray-600">
        <button className="sidebar-label-container flex items-center w-full justify-between text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
          <Link className="sidebar-title m-3 font-semibold" to="/klippvaror">
            Klippvaror
          </Link>
          <AiOutlineRight size="10" />
        </button>
      </div>
    </nav>
  );
}

export default SalesCategory;
