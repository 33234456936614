import { createContext, useState, useEffect, useMemo } from 'react';
import { useProductContext } from './ProductContext';
import { useUserContext } from './userContext';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const storedCartItems = localStorage.getItem('cartItems');
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });
  const [isProductListUpdate, setIsProductListUpdate] = useState(false); // Ny flagga för att spåra uppdateringar från produktlistan
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { products, discounts, klippvarorData } = useProductContext();
  const { user } = useUserContext();


  // Ny state-variabel för samleverans
  const [isCombinedDelivery, setIsCombinedDelivery] = useState(() => {
    const storedValue = localStorage.getItem('isCombinedDelivery');
    return storedValue ? JSON.parse(storedValue) : false;
  });

  useEffect(() => {
    if (user) {
      const currentUserCart = localStorage.getItem(`cartItems_${user.uid}`);
      if (currentUserCart) {
        setCartItems(JSON.parse(currentUserCart));
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      localStorage.setItem(`cartItems_${user.uid}`, JSON.stringify(cartItems));
    }
  }, [cartItems, user]);

  useEffect(() => {
    if (!user) {
      setCartItems([]);
    }
  }, [user]);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const saveCartToLocalStorage = (items) => {
    localStorage.setItem('cartItems', JSON.stringify(items));
  };

  useEffect(() => {
    saveCartToLocalStorage(cartItems);
  }, [cartItems]);

const getPrefixedInventoryNumber = (product, klippvarorData) => {
  if (klippvarorData?.lotSerialNumber && klippvarorData?.expirationDate) {
    return `${product.inventoryNumber}`;
  }
  return product.inventoryNumber;
};
  const productIncrements = useMemo(() => products.map(product => ({
    inventoryNumber: product.inventoryNumber,
    increment: parseInt(product.attributes.find(attribute => attribute.id === "FRP")?.value) || 1 
  })), [products]);

  const addToCart = (item, lotSerialNumber, expirationDate, quantity, formattedDiscountedPrice) => {
    // Använd getPrefixedInventoryNumber för att få rätt inventoryNumber
    const prefixedInventoryNumber = getPrefixedInventoryNumber(item, { lotSerialNumber, expirationDate });
  
    // Kontrollera om varan redan finns i varukorgen
    const isItemInCart = cartItems.find(cartItem => cartItem.inventoryNumber === prefixedInventoryNumber);

    let incrementValue;
  
    if (isItemInCart) {
      // Om varan redan finns i varukorgen, använd cartItem.attributes för att hämta FRP-attributet
      const cartFrpAttribute = isItemInCart.attributes?.find(attribute => attribute.id === "FRP");
      incrementValue = cartFrpAttribute ? parseInt(cartFrpAttribute.value) : 1;
    } else {
      // Om varan inte finns i varukorgen, använd product.attributes för att hämta FRP-attributet
      const productFrpAttribute = item.attributes?.find(attribute => attribute.id === "FRP");
      incrementValue = productFrpAttribute ? parseInt(productFrpAttribute.value) : 1;
    }
    if (isItemInCart) {
      // Uppdatera kvantitet om varan redan finns i varukorgen
      setCartItems(
        cartItems.map(cartItem =>
          cartItem.inventoryNumber === prefixedInventoryNumber
            ? {
                ...cartItem,
                quantity: cartItem.quantity + incrementValue,
                discountCode: item.discountCode,
                ...(lotSerialNumber && { lotSerialNumber }),
                ...(expirationDate && { expirationDate }),
                ...(formattedDiscountedPrice !== undefined && { discountedPrice: formattedDiscountedPrice })
              }
            : cartItem
        )
      );
    } else {
      // Lägg till produkten i varukorgen om den inte redan finns
      setCartItems([
        ...cartItems,
        {
          ...item,
          quantity: incrementValue,
          inventoryNumber: prefixedInventoryNumber,
          discountCode: item.discountCode,
          ...(lotSerialNumber && { lotSerialNumber }),
          ...(expirationDate && { expirationDate }),
          ...(formattedDiscountedPrice !== undefined && { discountedPrice: formattedDiscountedPrice })
        }
      ]);
    }

   
    
    // Spara varukorgen till localStorage
    saveCartToLocalStorage(cartItems);
  };

  const removeFromCart = (item, lotSerialNumber, expirationDate) => {
    // Använd getPrefixedInventoryNumber för att få rätt inventoryNumber
    const prefixedInventoryNumber = getPrefixedInventoryNumber(item, { lotSerialNumber, expirationDate });
  
    const isItemInCart = cartItems.find(cartItem => cartItem.inventoryNumber === prefixedInventoryNumber);
  
    if (isItemInCart) {
      // Hämta increment-värdet från cartItem.attributes om varan redan finns i varukorgen
      const cartFrpAttribute = isItemInCart.attributes?.find(attribute => attribute.id === "FRP");
      const incrementValue = cartFrpAttribute ? parseInt(cartFrpAttribute.value) : 1;
  
      const newQuantity = isItemInCart.quantity - incrementValue;
  
      if (newQuantity <= 0) {
        // Ta bort produkten från varukorgen om kvantiteten är 0 eller mindre
        setCartItems(cartItems.filter(cartItem => cartItem.inventoryNumber !== prefixedInventoryNumber));
      } else {
        // Uppdatera kvantiteten om den är större än 0
        setCartItems(
          cartItems.map(cartItem =>
            cartItem.inventoryNumber === prefixedInventoryNumber
              ? { ...cartItem, quantity: newQuantity }
              : cartItem
          )
        );
      }
    }
  
    // Spara varukorgen till localStorage
    saveCartToLocalStorage(cartItems);
  };

  const clearCart = () => {
    setCartItems([]);
    saveCartToLocalStorage([]);
  };

  const getShippingStatus = (cartTotal) => {
    const freeShippingThreshold = 6500;
    const amountRemaining = freeShippingThreshold - cartTotal;
    const percentage = Math.min((cartTotal / freeShippingThreshold) * 100, 100);
    const isFreeShipping = cartTotal >= freeShippingThreshold;
    
    return { cartTotal, amountRemaining, percentage, isFreeShipping };
  };

  const calculateCampaignDiscount = (cartItems, discounts) => {
    let totalDiscount = 0;
    const discountQuantities = {};
  
    cartItems.forEach(item => {
      if (item.discountCodes && item.discountCodes.length > 0) {
        item.discountCodes.forEach(code => {
          const productDiscount = discounts.find(discount => discount.discountCode === code);
          if (productDiscount && productDiscount.discountBreakpoints) {
            if (!discountQuantities[code]) {
              discountQuantities[code] = 0;
            }
            discountQuantities[code] += item.quantity;
          }
        });
      }
    });
  
    for (const discountCode in discountQuantities) {
      const discount = discounts.find(d => d.discountCode === discountCode);
      if (discount) {
        const discountBreakpoint = discount.discountBreakpoints[0];
        if (discountBreakpoint) {
          const quantity = discountQuantities[discountCode];
          const discountAmount = discountBreakpoint.discountAmount;
          const discountQuantity = discountBreakpoint.breakQuantity;
  
          if (quantity >= discountQuantity) {
            const discountMultiplier = Math.floor(quantity / discountQuantity);
            totalDiscount += discountAmount * discountMultiplier;
          }
        }
      }
    }
  
    return totalDiscount;
  };

  const campaignDiscount = useMemo(() => calculateCampaignDiscount(cartItems, discounts), [cartItems, discounts]);

  useEffect(() => {
  //  console.log("Campaign discount in Cart component:", campaignDiscount);
  }, [campaignDiscount]);
  const bestItems = cartItems.filter(item => 
    item.attributes.some(attribute => attribute.id === "BEST" && attribute.value === "1")
  );
  
  const nonBestItems = cartItems.filter(item => 
    !item.attributes.some(attribute => attribute.id === "BEST") || item.attributes.some(attribute => attribute.id === "BEST" && attribute.value === "0")
  );

  
  // Beräkna totalpris för BEST-produkter
  const getBestItemsTotal = () => {
    return bestItems.reduce((total, item) => {
      const price = item.discountedPrice !== null && item.discountedPrice !== undefined ? item.discountedPrice : item.defaultPrice;
      return total + price * item.quantity;
    }, 0);
  };

  
  // Beräkna totalpris för vanliga produkter
  const getNonBestItemsTotal = () => {
    return nonBestItems.reduce((total, item) => {
      const price = item.discountedPrice !== null && item.discountedPrice !== undefined ? item.discountedPrice : item.defaultPrice;
      return total + price * item.quantity;
    }, 0);
  };
 
  // Beräkna totalen beroende på samleverans
  const getTotal = () => {
    if (isCombinedDelivery) {
      return cartItems.reduce((total, item) => {
        const price = item.discountedPrice !== null && item.discountedPrice !== undefined ? item.discountedPrice : item.defaultPrice;
        return total + price * item.quantity;
      }, 0);
    } else {
      return getNonBestItemsTotal();
    }
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        isCombinedDelivery,
        setIsCombinedDelivery,
        getNonBestItemsTotal,
        getBestItemsTotal,
        getTotal,
        toggleCart,
        isCartOpen,
        setIsCartOpen,
        getShippingStatus,
        campaignDiscount,
        bestItems,
        nonBestItems,
        isProductListUpdate,
        setIsProductListUpdate
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
