import React, { useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import Products from '../Products/Products';
import { useProductContext } from '../context/ProductContext';
import CategoryBanner from '../components/CategoryBanner';
import { useUserContext} from '../context/userContext';
import DiscountNotification from '../components/DiscountNotification';

const CategoryPage = () => {
    const { mainCategorySlug, subCategorySlug } = useParams();
    const { filterProducts, mainCategories, subCategories } = useProductContext();
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [sortOrder, setSortOrder] = useState('default');
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [showInStockOnly, setShowInStockOnly] = useState(false);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
const { user } = useUserContext();

  const getColorString = (slug) => {
    switch(slug) {
      case 'kex-brod-kakor':
        return 'before:border-kexbrodkakor';
      case 'antipasto':
        return 'before:border-antipasto';
        case 'olivolja-balsamico-vinager':
        return 'before:border-olivoljabalsamicovinager';
            case 'pasta-ris-soppor':
        return 'before:border-pastarissoppor';
            case 'kryddor-salt-smaksattare':
        return 'before:border-kryddorsaltsmaksattare';
            case 'saser-dressing-bbq':
        return 'before:border-saserdressingbbq';
             case 'marmelad-honung-curd':
        return 'before:border-marmeladhonungcurd';
            case 'varma-drycker':
        return 'before:border-varmadrycker';
            case 'dryck':
        return 'before:border-dryck';
            case 'chips-snacks':
        return 'before:border-chipssnacks';
        case 'konfektyr':
            return 'before:border-konfektyr';
     
      default:
        return 'before:border-defaultcolor';
    }
  };


  const colorString = getColorString(mainCategorySlug);
  useEffect(() => {
    if (mainCategories) {
        let mainCategory = null;
        let subCategory = null;

        // Hitta rätt huvudkategori baserat på slug
        if (mainCategorySlug) {
            mainCategory = mainCategories.find(category => category.slug === mainCategorySlug);
        }

        // Om vi är på en underkategori, hitta den rätta underkategorin från huvudkategorin
        if (subCategorySlug && mainCategory) {
            subCategory = mainCategory.subCategories.find(subCat => subCat.slug === subCategorySlug);
        }

        if (subCategory) {
            console.log("Hittad underkategori:", subCategory);
            setSelectedCategory(subCategory);
        } else if (mainCategory) {
            console.log("Hittad huvudkategori:", mainCategory);
            setSelectedCategory(mainCategory);
        }
    }
}, [mainCategories, mainCategorySlug, subCategorySlug]);
const filteredProducts = useMemo(() => {
    let filtered = filterProducts(mainCategorySlug, selectedSupplier, selectedSubCategory || subCategorySlug, selectedAttributes);

    // Filter för att endast visa produkter i lager
    if (showInStockOnly) {
        filtered = filtered.filter(product => product.warehouseDetails.some(detail => detail.quantityOnHand > 0));
    }

    return filtered;
}, [mainCategorySlug, selectedSupplier, selectedSubCategory, subCategorySlug, showInStockOnly, selectedAttributes, filterProducts]);

const availableAttributes = useMemo(() => {
    const attributeIds = ['GLUTENFRI', 'EKO', 'SF', 'VEGAN'];

    const filteredProductsAttributes = filteredProducts.flatMap(product => 
        product.attributes?.filter(attr => attributeIds.includes(attr.id) && attr.value === '1').map(attr => attr.id) || []
    );

    return [...new Set(filteredProductsAttributes)];  // Ta bort dubbletter
}, [filteredProducts]);


    useEffect(() => {
        setSelectedSupplier('');
        setSelectedFilters([]);
    }, [mainCategorySlug, subCategorySlug]);

    const handleSupplierChange = (event) => {
        const supplier = event.target.value;
        setSelectedSupplier(supplier);
        if (supplier && !selectedFilters.includes(supplier)) {
            setSelectedFilters([...selectedFilters, supplier]);
        }
    };

    const handleSortChange = (event) => {
        setSortOrder(event.target.value);
    };

    const removeFilter = (filter) => {
        setSelectedFilters(selectedFilters.filter(f => f !== filter));
        if (filter === selectedSupplier) {
            setSelectedSupplier('');
        }
    };
    useEffect(() => {
        setSelectedSubCategory('');
    }, [mainCategorySlug]);
 
    const handleAttributeChange = (attributeId) => {
        if (selectedAttributes.includes(attributeId)) {
            setSelectedAttributes(selectedAttributes.filter(attr => attr !== attributeId));
        } else {
            setSelectedAttributes([...selectedAttributes, attributeId]);
        }
    };

    const filteredSupplierNames = [...new Set(filteredProducts.flatMap(product => product.supplierDetails.map(supplier => supplier.supplierName)))];

    const numFilteredProducts = filteredProducts.length;

    const sortProducts = (products, order) => {
        switch (order) {
            case 'default':
                return products;
            case 'nameAZ':
                return products.slice().sort((a, b) => (a.description || '').localeCompare(b.description || ''));
            case 'nameZA':
                return products.slice().sort((a, b) => (b.description || '').localeCompare(a.description || ''));
            case 'supplierAZ':
                return products.slice().sort((a, b) => (a.supplierDetails[0]?.supplierName || '').localeCompare(b.supplierDetails[0]?.supplierName || ''));
            case 'supplierZA':
                return products.slice().sort((a, b) => (b.supplierDetails[0]?.supplierName || '').localeCompare(a.supplierDetails[0]?.supplierName || ''));
            case 'supplierProductAZ':
                return products.slice().sort((a, b) => {
                    const supplierCompare = (a.supplierDetails[0]?.supplierName || '').localeCompare(b.supplierDetails[0]?.supplierName || '');
                    if (supplierCompare !== 0) return supplierCompare;
                    return (a.description || '').localeCompare(b.description || '');
                });
            default:
                return products;
        }
    };
    const sortedProducts = sortProducts(filteredProducts, sortOrder);
    
 // console.log('filtrerade och sorterade produkter på kategorisidan:',sortedProducts  );
    return (
        <div className='p-8 mt-44 md:mt-32'>
            <DiscountNotification />
            <nav className="flex p-5" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center dark:text-white">
                        <Link className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" to="/">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            Hem
                        </Link>
                    </li>
                    <li aria-current="page" className='dark:text-gray-200'>
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 dark:text-gray-200 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            <Link to={`/${mainCategorySlug}`}>{mainCategorySlug}</Link>
                        </div>
                    </li>
                </ol>
            </nav>
            <CategoryBanner mainCategorySlug={mainCategorySlug} subCategorySlug={subCategorySlug} />
            <h1 className={`${colorString}  before:border-l-8 before:border-solid before:mr-3 text-2xl p-2 md:p-0 md:text-4xl font-extrabold dark:text-white my-5`}>
                {selectedCategory ? selectedCategory.description : 'Category'}
            </h1>
            <h2 className='dark:text-white pb-5'>{numFilteredProducts} varor</h2>
            {/* Dropdown för att välja leverantör */}
            {selectedCategory && selectedCategory.subCategories && selectedCategory.subCategories.length > 0 && (
  <select
    className='dark:bg-transparent dark:text-white dark:border-gray-600 border-slate-300 mr-3'
    value={selectedSubCategory}
    onChange={(e) => setSelectedSubCategory(e.target.value)}
  >
    <option value="">Alla underkategorier</option>
    {selectedCategory.subCategories.map((subCat, index) => (
      <option key={index} value={subCat.slug}>{subCat.description}</option>
    ))}
  </select>
)}
            <select className='dark:bg-transparent dark:text-white dark:border-gray-600 border-slate-300 mr-3' value={selectedSupplier} onChange={handleSupplierChange}>
                <option value="">Alla Leverantörer</option>
                {filteredSupplierNames.map((supplierName, index) => (
                    <option key={index} value={supplierName}>{supplierName}</option>
                ))}
            </select>
            <select className='dark:bg-transparent dark:text-white w-auto md:w-auto dark:border-gray-600 border-slate-300' value={sortOrder} onChange={handleSortChange}>
                <option value="default">Standard</option>
                <option value="nameAZ">Produktnamn: Fallande (A-Ö)</option>
                <option value="nameZA">Produktnamn: Stigande (Ö-A)</option>
                <option value="supplierAZ">Leverantör: Fallande (A-Ö)</option>
                <option value="supplierZA">Leverantör: Stigande (Ö-A)</option>
                <option value="supplierProductAZ">Leverantör & Produkt: Fallande (A-Ö)</option>
            </select>
            { user && (
            <div className='m-3 dark:bg-transparent dark:text-white dark:border-gray-600 border-slate-300'>
                <label >
                    <input
                        type="checkbox"
                        checked={showInStockOnly}
                        onChange={(e) => setShowInStockOnly(e.target.checked)}
                   className='mr-2' />
                    Visa endast produkter i lager
                </label>
            </div>
            )}
            {availableAttributes.length > 0 && (
    <div className="attribute-filters mt-3 ">
        <h3 className="font-bold text-lg dark:text-white mb-3">Filtrera efter produktmärkning</h3>
        {availableAttributes.includes('GLUTENFRI') && (
            <label className=" bg-orange-400 inline-block text-white font-bold text-sm p-1 px-2 m-3 rounded">
                <input className='mr-2'
                    type="checkbox" 
                    value="GLUTENFRI" 
                    onChange={() => handleAttributeChange('GLUTENFRI')} 
                    checked={selectedAttributes.includes('GLUTENFRI')}
                /> Glutenfri
            </label>
        )}
        {availableAttributes.includes('EKO') && (
            <label className="bg-emerald-600 inline-block text-white font-bold text-sm p-1 px-2 m-3 rounded">
                <input 
                    type="checkbox" 
                    value="EKO" 
                    onChange={() => handleAttributeChange('EKO')} 
                    checked={selectedAttributes.includes('EKO')}
                /> Ekologisk
            </label>
        )}
        {availableAttributes.includes('SF') && (
            <label className="bg-blue-600 inline-block text-white font-bold text-sm p-1 px-2 m-3 rounded">
                <input 
                    type="checkbox" 
                    value="SF" 
                    onChange={() => handleAttributeChange('SF')} 
                    checked={selectedAttributes.includes('SF')}
                /> Sockerfri
            </label>
        )}
        {availableAttributes.includes('VEGAN') && (
            <label className="bg-green-500 inline-block text-white font-bold text-sm p-1 px-2 m-3 rounded">
                <input 
                    type="checkbox" 
                    value="VEGAN" 
                    onChange={() => handleAttributeChange('VEGAN')} 
                    checked={selectedAttributes.includes('VEGAN')}
                /> Vegansk
            </label>
        )}
    </div>
)}
           
            {/* Collector div för valda alternativ */}
            <div className="mt-4 ">
                {selectedFilters.map((filter, index) => (
                    <span key={index} className="inline-flex items-center px-3 py-1 mr-2 text-sm font-medium text-white bg-indigo-600 rounded">
                        {filter}
                        <button onClick={() => removeFilter(filter)} className="ml-2 text-white">
                            ✕
                        </button>
                    </span>
                ))}
            </div>

            <Products products={sortedProducts} />
        </div>
    );
};

export default CategoryPage;
