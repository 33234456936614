// Products.js
import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../components/Card';
import { v4 as uuidv4 } from 'uuid';

const Products = ({ products, selectedCategory, selectedSupplier }) => {
  return (
    <section className="card-container grid grid-cols-1 sm:grid-cols-2  md-grid-cols-3 lg:grid-cols-4 mt-10">
      {products.map((product) => (
     
          <Card key={uuidv4()} product={product} selectedCategory={selectedCategory} />
      ))}
    </section>
  );
};

export default Products;
