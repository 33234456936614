import React, { useEffect, useState } from 'react';
import axios from 'axios';

const InstagramFeed = () => {
  const [mediaDetails, setMediaDetails] = useState([]);

  const accessToken = 'EAAQkPmjuwokBOwGKxwwLXsmXZCu8w67VXuQFitkWywNPkBRCZC7iSZCSYq7Y6i1LBLfqikx6dp0vIp8z0ftTJFc4h5SLQZCRqoFmRbmVRUAKOMWwyonQZB8ipzY01w4vCysLsXCk2aOlD5hkC3dykrPl8h940eQZAYgtQMiayZBrJzcIlYJTZCrRpbvck3jFvjuldeKFfp3roV18G5thsAZDZD';

  useEffect(() => {
    const fetchMediaIds = async () => {
      try {
        // Steg 1: Hämta de senaste 10 mediaobjekten (ID:n)
        const response = await axios.get(`https://graph.facebook.com/v16.0/17841402120413112?fields=media.limit(10){id}&access_token=${accessToken}`);
        const mediaIds = response.data.media.data;

        // Steg 2: Hämta detaljer för varje mediaobjekt med en GET-begäran
        const mediaPromises = mediaIds.map(async (media) => {
          const mediaDetail = await axios.get(`https://graph.facebook.com/v16.0/${media.id}?fields=id,media_type,media_url,caption,permalink,comments_count,like_count,username&access_token=${accessToken}`);
          return mediaDetail.data;
        });

        // Vänta tills alla mediaobjektdetaljer är hämtade
        const mediaResults = await Promise.all(mediaPromises);

        // Sätt resultatet i state för att visa dem
        setMediaDetails(mediaResults);
      } catch (error) {
        console.error('Error fetching media details:', error);
      }
    };

    fetchMediaIds();
  }, [accessToken]);

  return (
    <div className="instagram-feed grid grid-cols-2">
      {mediaDetails.map((media) => (
       
  <card key={media.id} class="bg-white border-gray-300 w-96 border">
    
    <header class="grid grid-cols-6 items-center p-3 border-b border-b-gray-300">
      
   
      
      <div class="col-span-5 text-sm font-semibold"> 
       {media.username}
      </div>
      
      <div class="">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
        </svg>
      </div>
      
    </header>
 
    <photo>
       <img src={media.media_url} />
    </photo>
    
    <content class="flex flex-col p-4 gap-3">
      
      
      
      <div class="text-sm font-semibold">
        {media.like_count} gillar
      </div>
      
      <div class="text-sm max-h-32 mb-5 pb-5">
        <span class="font-semibold">{media.username} </span>{media.caption}.
      </div>
      <div className='grid bg-gray-200 grid-cols-2 h-full w-full gap-2'>
      <div class="text-gray-500 text-sm">
        {media.comments_count} kommentarer
      </div>
      
      <div class="text-gray-500 text-sm">
        <a href={media.permalink} target="_blank" rel="noopener noreferrer">Visa på Instagram</a>
      </div>
      </div>
    </content>
    
  
    
  </card>
  

         
       
        
       
      ))}
    </div>
  );
};

export default InstagramFeed;
