import React, { useEffect, useState } from 'react';
import { useProductContext } from '../context/ProductContext';
import { useLocation } from 'react-router-dom';
import Products from '../Products/Products';
import { searchProducts } from '../services/searchUtils'; // Importera search-funktionen

const SearchResultsPage = () => {
  const { products } = useProductContext();
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();

  // Hämta sökqueryn från URL
  const query = new URLSearchParams(location.search).get('query');

  useEffect(() => {
    if (query) {
      // Använd den gemensamma söklogiken
      const results = searchProducts(products, query);
      setSearchResults(results.slice(0, 80)); // Begränsa till 80 resultat
    } else {
      setSearchResults([]);
    }
  }, [products, query]);

  return (
    <div className='mt-32'>
      <h1 className="text-3xl font-extrabold dark:text-white pl-5 pt-12">Visar alla resultat för "{query}"</h1>
      {searchResults.length > 0 ? (
        <Products products={searchResults} />
      ) : (
        <p>No results found</p>
      )}
    </div>
  );
};

export default SearchResultsPage;
