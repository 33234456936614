import React, { useEffect, useRef, useState } from "react";
import Category from "./Category/Category";
import SalesCategory from "./Category/SalesCategory";
import Suppliers from "./Suppliers";
import { AiOutlineClose } from "react-icons/ai";
import { useAuth } from "../context/authContext";

const Sidebar = ({ isOpen, toggleSidebar, handleChange }) => {
  const sidebarRef = useRef(null);
  const { currentUser } = useAuth();

  return (
    <>
      <aside
        ref={sidebarRef}
        id="separator-sidebar"
        className={`fixed left-0 top-0 md:top-36 md:z-40 z-50 md:w-72 w-full h-screen transition-transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-8  overflow-y-auto bg-gray-50 dark:bg-gray-800 dark:text-white">
          <AiOutlineClose
            className="bg-slate-600 cursor-pointer p-1 m-3 text-white block"
            size={30}
            onClick={toggleSidebar} 
          />
          {currentUser && (
            <SalesCategory handleChange={handleChange} />
          )}
          <Category handleChange={handleChange} onClick={toggleSidebar} />
          <Suppliers handleChange={handleChange} onClick={toggleSidebar} />
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
