import React from 'react';

const TermsAndConditions = () => {
    return (
        <div>
            <h1>Terms and Conditions</h1>
            <p>Welcome to our Terms and Conditions page.</p>
            <p>Please read these terms and conditions carefully before using our website.</p>
        </div>
    );
};

export default TermsAndConditions;