import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/authContext';
import { Link, useNavigate } from 'react-router-dom';
import GastroLogga from "../imgs/Gastronomileverantoren_logotyp.png";

function Login() {
  const { login, currentUser, logout } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await login(email, password);
    } catch (error) {
      const errorMessage = mapAuthError(error);
      setError(errorMessage);
    }
  };

  const mapAuthError = (error) => {
    switch (error.code) {
      case 'auth/invalid-email':
        return 'Ogiltig e-postadress.';
      case 'auth/user-disabled':
        return 'Detta konto har inaktiverats.';
      case 'auth/user-not-found':
        return 'Ingen användare hittades med denna e-postadress.';
      case 'auth/wrong-password':
        return 'Fel lösenord.';
        case 'auth/missing-password':
          return 'Vänligen fyll i ditt lösenord.';
          case 'auth/invalid-credential':
        return 'Ogiltiga inloggningsuppgifter.';
        case 'auth/too-many-requests':
        return 'Du har försökt logga in för många gånger och kontot har temporärt avaktiverats. Vänligen återställ ditt lösenord eller försök igen om en liten stund.'
      default:
        return error.message; // Fallback till Firebase-meddelandet om ingen matchning hittas
    }
  };

  useEffect(() => {
    if (currentUser) {
      navigate('/handla');
    }
  }, [currentUser, navigate]);

  return (
    <div className='dark:bg-gray-800'>
      {currentUser ? (<>
      
        <div className='container-full p-20 '>
        <h1 className="text-2xl text-center my-10 text-bold text-slate-900">Inloggad som {currentUser.displayName} - Omdirigerar till butik...</h1>
          <div className="rounded-md bg-blue-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
    
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
       
          <p className="text-sm text-blue-700">Du är inloggad som {currentUser.email}</p>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
           
            <button className='whitespace-nowrap font-medium text-blue-700 hover:text-blue-600' onClick={logout}>Logga ut</button>
              <span aria-hidden="true"> &rarr;</span>
            
          </p>
        </div>
      </div>
    </div>
    </div>  
    </>
      
      ) : (
        <div className="flex min-h-full flex-1 flex-col col-span-2 container mx-auto w-full md:w-1/2 justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto md:w-full sm:max-w-lg">
          <img
            className="mx-auto h-10 w-auto"
            src={GastroLogga}
            alt="GastronomiLeverantören"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
           Logga in
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm"></div>
        <form onSubmit={handleLogin}>
          <div className="mt-2">
          <input type="email" autoComplete="email" className="dark:bg-transparent dark:ring-gray-600 block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-transparent dark:border-gray-600"
          placeholder="dittnamn@exempel.se" value={email} onChange={(e) => setEmail(e.target.value)} />
      
      </div>
      <div className="mt-2">
          <input type="password" autoComplete="current-password" className="dark:bg-transparent dark:ring-gray-600 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Lösenord" />
          </div>
          <button className='rounded-md bg-white px-3 py-2 text-sm font-semibold my-5 text-gray-900 shadow-sm ring-1 ring-inset dark:border-gray-600 hover:bg-gray-50 dark:bg-slate-600 dark:text-gray-200' type="submit">Logga in</button>
          {error &&  <div className="rounded-md bg-red-50 dark:bg-transparent  p-4">
      <div className="flex">
        <div className="flex-shrink-0">
        
        </div>
        <div className="ml-3 ">
          <h3 className="text-sm font-medium text-red-800 dark:text-red-500">Något är fel vid inloggningen:</h3>
          <div className="mt-2 text-sm text-red-700 dark:text-red-500">
            <ul role="list" className="list-disc space-y-1 pl-5">
              <li>{error}</li>
             
            </ul>
          </div>
        </div>
      </div>
    </div>}
        </form>
        
          <p className="mt-10 text-center dark:text-gray-300 text-sm text-gray-600">
            Glömt lösenordet?{' '}
            <Link to="/forgot-password" className="font-semibold leading-6 text-yellow-600 hover:text-yellow-800">
              Klicka här för att återställa ditt lösenord
            </Link>
          </p>
        </div>
     
      )}
    </div>
  );
}

export default Login;
