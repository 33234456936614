// Header.js
import React from 'react';
import Nav from '../Navigation/Nav';
import TopHeader from './TopHeader';
import { useLocation } from 'react-router-dom';

const Header = ({ handleInputChange, query, toggleCart, showModal, handleChange, toggleSidebar, isSidebarOpen }) => {  const location = useLocation(); // Hämta aktuell rutt

  // Lista över rutter där Nav inte ska visas
  const routesWithoutNav = [
    '/login',
    '/checkout',
    '/reset-password',
    '/forgot-password',
    '/',
    '/om-foretaget',
    '/bli-aterforsaljare',
    '/kontakt',
    // Lägg till fler rutter om nödvändigt
  ];

  // Kontrollera om den aktuella sökvägen finns i listan över rutter där Nav inte ska visas
  const shouldShowNav = !routesWithoutNav.includes(location.pathname);
  
  
  return (
    <header className='bg-white dark:bg-gray-900 shadow-lg fixed w-full z-50 top-0 start-0 border-b border-gray-200 dark:border-gray-600'>
      <TopHeader />   {/* Visa Nav endast om sökvägen inte finns i listan */}
      {shouldShowNav && (
        <Nav 
          handleInputChange={handleInputChange} 
          handleChange={handleChange} 
          query={query} 
          toggleSidebar={toggleSidebar} 
          isSidebarOpen={isSidebarOpen} 
          toggleCart={toggleCart}  
          showModal={showModal} 
        />
      )}
    </header>
  );
};

export default Header;
