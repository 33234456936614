import React from 'react';
import { useLocation } from 'react-router-dom';

const OrderSuccess = () => {
  const location = useLocation();
  const { nonBestItemsOrder, bestItemsOrder, combinedOrder } = location.state || {}; // Hämta orderdokumenten

  // Funktion för att beräkna subtotal, rabatt och total för en specifik order
  const calculateTotals = (orderData) => {
    let subtotal = 0;
    let totalDiscount = 0;

    orderData.lines.forEach((line) => {
      const quantity = line.quantity.value;
      const price = line.price.value;
      const formattedDiscountedPrice = line.unitPrice ? line.unitPrice.value : price;

      subtotal += price * quantity;
      totalDiscount += (price - formattedDiscountedPrice) * quantity;
    });

    const shippingCost = 0; // Ändra om du har dynamiska fraktkostnader
    const orderTotal = subtotal - totalDiscount + shippingCost;

    return { subtotal, totalDiscount, shippingCost, orderTotal };
  };
  /* Rendera ordern baserat på orderData
  const renderOrder = (orderData, orderTitle) => {
    const { subtotal, totalDiscount, shippingCost, orderTotal } = calculateTotals(orderData);

    console.log(orderData, 'total discount', totalDiscount, 'shipping cost', shippingCost, 'order total', orderTotal);
    return (
      <div className="mt-10 border-t border-gray-200">
        <h2 className="text-lg font-medium text-gray-900">{orderTitle}</h2>
        <div className="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
          {orderData.lines.map((line, index) => (
            <div key={index} className="flex space-x-6 border-b border-gray-200 py-5">
              <div className="flex flex-auto flex-col">
                <div>
                  <h4 className="font-medium text-gray-900">
                    <a>{line.itemDescription.value}</a>
                  </h4>
                </div>
                <div className="mt-6 flex flex-1 items-end">
                  <dl className="flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6">
                    <div className="flex">
                      <dt className="font-medium text-gray-900">Antal:</dt>
                      <dd className="ml-2 text-gray-700">{line.quantity.value}</dd>
                    </div>
                    
                  </dl>
                </div>
              </div>
            </div>
          ))}
          <div className="sm:pl-6">
            <dl className="space-y-6 border-t border-gray-200 pt-10 text-sm">
              <h3 className="text-lg">Ordersummering:</h3>
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">Deltotal</dt>
                <dd className="text-gray-700">
                  {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(subtotal)}
                </dd>
              </div>
              {totalDiscount > 0 && (
                <div className="flex justify-between">
                  <dt className="flex font-medium text-gray-900">Rabatt</dt>
                  <dd className="text-gray-700">
                    -{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalDiscount)}
                  </dd>
                </div>
              )}
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">Frakt:</dt>
                <dd className="text-gray-700">
                  {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(shippingCost)}
                </dd>
              </div>
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">Ordertotal:</dt>
                <dd className="text-gray-900">
                  {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(orderTotal)}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    );
  }; */

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-3xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="max-w-xl">
          <h1 className="text-base font-medium text-indigo-600">Tack för din order!</h1>
          <p className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl">Den är på väg!</p>
          <p className="mt-2 text-base text-gray-500">
            Din order behandlas av oss och du får inom kort ett ordererkännande skickat till dig.
            Du kan även kontrollera status på din order under "Orderhistorik".
          </p>
        </div>

       {/* Rendera kombinerad order om samleverans är vald */}
       {/*combinedOrder && renderOrder(combinedOrder, 'Samlad Beställning')}

        {/* Rendera beställningen för ordinarie varor om samleverans inte är vald */}
        {/* nonBestItemsOrder && renderOrder(nonBestItemsOrder, 'Ordinarie Beställning')}

        {/* Rendera beställningen för beställningsvaror om samleverans inte är vald */}
        { /*bestItemsOrder && renderOrder(bestItemsOrder, 'Beställningsvaror') */}
      </div>
    </div>
  );
};

export default OrderSuccess;
