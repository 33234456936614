import React from 'react';

const Integritetspolicy = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Integritetspolicy</h1>
            <p className="mb-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam scelerisque leo nec magna fermentum, a cursus lorem facilisis. 
            </p>
            <p className="mb-2">
                Vivamus vel turpis at libero suscipit tincidunt. Integer non orci nec nulla tincidunt tincidunt. 
            </p>
            <p className="mb-2">
                Donec vel magna nec sapien vehicula tincidunt. Sed sit amet arcu a justo facilisis tincidunt. 
            </p>
            <p className="mb-2">
                Proin nec urna nec urna tincidunt tincidunt. Nulla facilisi. 
            </p>
        </div>
    );
};

export default Integritetspolicy;