import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // För att fånga upp slug från URL:en
import { db } from '../firebase/firebase';
import { getDocs, collection, query, where } from 'firebase/firestore';
import Products from '../Products/Products'; // Komponent för att visa produkterna

const PromotionPage = () => {
  const { slug } = useParams(); // Hämta slugen från URL:en
  const [promotion, setPromotion] = useState(null); // För att lagra kampanjen
  const [isLoading, setIsLoading] = useState(true); // Laddningsindikator
  const [hasError, setHasError] = useState(false); // Felhantering

  useEffect(() => {
    const fetchPromotionBySlug = async () => {
      try {
        console.log("Searching for promotion with slug:", slug); // Lägg till log för att se slugen
        const promotionsRef = collection(db, 'promotionalContent');
        const q = query(promotionsRef, where('slug', '==', slug)); // Hämta kampanjen baserat på slug
        const promotionSnapshot = await getDocs(q);
  
        if (!promotionSnapshot.empty) {
          const promotionData = promotionSnapshot.docs[0].data();
          console.log("Promotion found:", promotionData); // Lägg till log för att se om data hittas
          setPromotion(promotionData);
        } else {
          console.log("No promotion found for slug:", slug); // Lägg till log om ingen kampanj hittas
          setHasError(true);
        }
      } catch (error) {
        console.error('Error fetching promotion:', error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchPromotionBySlug();
  }, [slug]);

  if (isLoading) {
    return <p>Laddar kampanj...</p>; // Laddningsindikator
  }

  if (hasError || !promotion) {
    return <p>Kampanjen kunde inte hittas.</p>; // Felmeddelande om något går fel
  }

  return (
    <div className="promotion-section p-8 mt-40">
      {/* Rendera kampanjens banner */}
      <div
           className="relative max-h-96 min-h-64 flex flex-col justify-center items-center text-center bg-cover bg-center"
           style={{ backgroundImage: `url(${promotion.bannerImage})` }}
         >
           {/* Overlay för att mörka ner bakgrunden lite */}
           <div className="absolute inset-0 bg-black opacity-50"></div>
           
           {/* Innehållet centrerat i mitten av sektionen */}
           <h1 className="relative z-10 text-4xl font-extrabold text-white">
             {promotion.title}
           </h1>
   {/* Rendera kampanjbeskrivning */}
   <p className='text z-10 text-white py-5'>{promotion.description}</p>
          {/* Länk till kampanjsidan baserat på slug */}
        
          </div>
      {/* Rendera kampanjens produkter */}
      {promotion.products && promotion.products.length > 0 ? (
        <Products products={promotion.products} />
      ) : (
        <p>Inga produkter tillgängliga för denna kampanj.</p>
      )}

      
    </div>
  );
};

export default PromotionPage;
