import React from "react";

import Products from "./Products";
import { useProductContext } from "../context/ProductContext";
import SpecialBanner from "../components/SpecialBanner";
const Sasong = () => {
    const { products} = useProductContext();
    const selectedSalesCategory = 'Säsong';
    const selectedSalesCategorySlug = 'sasong';
  console.log('params värde', selectedSalesCategory);
    // Filtrera produkter baserat på både kategori och leverantör
    const filteredProducts = products.filter(product => {
    
      if (selectedSalesCategory) {
        return product.salesCategories.some(salesCategory => salesCategory.description === selectedSalesCategory);
      }
      // Om ingen kategori eller leverantör är vald, visa alla produkter
      else {
        return true;
      }
  
  
    });

    
    return (
      <div className='p-8 mt-20'>
      <SpecialBanner slug={selectedSalesCategorySlug} />
      
        <h1 className="text-4xl font-extrabold dark:text-white">Säsongsprodukter</h1>
        <Products products={filteredProducts} />
      </div>
    );
  };
  
  export default Sasong;