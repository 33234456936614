// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeFirestore,loadBundle, doc, getDoc, collection, persistentLocalCache, persistentMultipleTabManager, getDocsFromCache, namedQuery, getFirestore, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, getDownloadURL, ref, } from "firebase/storage";
import { getPerformance } from "firebase/performance";
import { compress, decompress } from 'lz-string';
import { openDB } from 'idb';
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBDit3FP97MCe-oQHU7K51EotB76HPNzX8",
  authDomain: "gastrodatabas.firebaseapp.com",
  projectId: "gastrodatabas",
  storageBucket: "gastrodatabas.appspot.com",
  messagingSenderId: "503453250796",
  appId: "1:503453250796:web:a433d7f0a391a1d05521c9",
  measurementId: "G-DJLT19GFXC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
      tabManager: persistentMultipleTabManager(),
      cacheSizeBytes: 10485760  // 10 MB, justera denna siffra beroende på behov
  })
});


// Skapa eller öppna en IndexedDB-databas och använd ett annat variabelnamn
const indexedDBPromise = openDB('product-store', 1, {
  upgrade(indexedDB) {
    if (!indexedDB.objectStoreNames.contains('products')) {
      indexedDB.createObjectStore('products', { keyPath: 'id' });
    }
  },
});

// Spara data i IndexedDB
export const saveProductsToIndexedDB = async (productsData) => {
  const indexedDB = await indexedDBPromise;
  console.log('Data innan den sparas i IndexedDB:', productsData);
  await indexedDB.put('products', { id: 'productsData', data: productsData });
  console.log('Data har sparats i IndexedDB');
  const savedData = await indexedDB.get('products', 'productsData');
  console.log('Verifierar sparad data från IndexedDB:', savedData);
};

// Hämta data från IndexedDB
export const getProductsFromIndexedDB = async () => {
  const indexedDB = await indexedDBPromise;
  const productsData = await indexedDB.get('products', 'productsData');
  console.log('IndexedDB-hämtning:', productsData);
  if (!productsData) {
    console.log('Ingen data hittades i IndexedDB');
  }
  return productsData ? productsData.data : undefined;
};

// Separat funktion för att ladda från server och uppdatera cache
const loadFromServerAndUpdateCache = async () => {
  try {
    const fileRef = ref(storage, 'bundles/productsData.json');
    const url = await getDownloadURL(fileRef);
    console.log("Hämtar productsData.json från:", url);

    const response = await fetch(url);
    const productsData = await response.json();
    console.log("Ny productsData.json laddad med", productsData.length, "produkter");

    // Spara produkterna i IndexedDB
    await saveProductsToIndexedDB(productsData);

    // Uppdatera cache-tidsstämpeln i localStorage
    const newTimestamp = new Date().toISOString();
    localStorage.setItem('productsDataCacheTimestamp', newTimestamp);
    console.log("Cache uppdaterad med ny timestamp (UTC):", newTimestamp);

    return productsData;
  } catch (error) {
    console.error("Fel vid laddning från servern och uppdatering av cache:", error);
    throw error;
  }
};

// Main funktion för att hantera datan
export const loadFirestoreBundleAndQuery = async () => {
  try {
    console.log("Laddar manuell jsonData från IndexedDB...");

    const cacheTimestamp = localStorage.getItem('productsDataCacheTimestamp');
    console.log("Cache timestamp från localStorage (rå):", cacheTimestamp);

    const lastUpdateRef = doc(getFirestore(), 'metadata', 'lastUpdate');
    const lastUpdateDoc = await getDoc(lastUpdateRef);
    const lastUpdate = lastUpdateDoc.exists() ? lastUpdateDoc.data().lastModifiedDateTime : null;
    console.log("Senaste lastUpdate från Firestore:", lastUpdate);

    // Jämför cache timestamp med Firestore's lastUpdate direkt
    if (!cacheTimestamp || cacheTimestamp < lastUpdate) {
      console.log("Cachen är utgången eller saknas. Laddar den senaste productsData.json från Firebase Storage...");
      return await loadFromServerAndUpdateCache();
    } else {
      // Om cachen är giltig, använd den cachade datan
      console.log("Använder cachad data från IndexedDB...");
      const productsData = await getProductsFromIndexedDB();
      if (!productsData) {
        console.log("Ingen data hittades i IndexedDB, laddar om från servern...");
        return await loadFromServerAndUpdateCache();  // Om inget hittades, ladda om
      }
      console.log("Cachad data laddad med", productsData.length, "produkter");
      return productsData;
    }
  } catch (error) {
    console.error("Fel vid laddning av productsData.json:", error);
    throw error;
  }
};







export const loadCategoryBundleAndQuery = async () => {
  try {
    const firestore = getFirestore();
    
    const metadataRef = doc(firestore, 'metadata', 'lastCategoryUpdate');
    const metadataDoc = await getDoc(metadataRef);
    const lastUpdate = metadataDoc.exists() ? metadataDoc.data().lastModifiedDateTime : null;

    const cachedBundleTimestamp = localStorage.getItem("lastCategoryBundleLoadTimestamp");
    let shouldReloadBundle = !cachedBundleTimestamp || new Date(cachedBundleTimestamp) < new Date(lastUpdate);

    if (shouldReloadBundle) {
      const bundleRef = ref(storage, "bundles/categoriesBundle.bundle");
      const url = await getDownloadURL(bundleRef);
      const response = await fetch(url);
      const bundleBuffer = await response.arrayBuffer();
      await loadBundle(firestore, bundleBuffer);
      localStorage.setItem("lastCategoryBundleLoadTimestamp", lastUpdate);
    }

    const query = await namedQuery(firestore, "categories-query");
    if (query) {
      const snapshot = await getDocsFromCache(query);

      // Vi itererar genom huvudkategorierna
      const categories = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const categoryData = doc.data();
          const categoryId = doc.id;

          // Hämta subcategories som en separat collection inom varje huvudkategori
          const subcategoriesRef = collection(firestore, `categories/${categoryId}/subCategories`);
          const subcategoriesSnapshot = await getDocs(subcategoriesRef);

          // Omvandla subcategories till en array
          const subCategories = subcategoriesSnapshot.docs.map((subDoc) => ({
            ...subDoc.data(),
            id: subDoc.id
          }));

          return {
            ...categoryData,
            subCategories, // Lägg till subcategories-arrayen till kategoridata
          };
        })
      );

      return categories;
    }

    return [];
  } catch (error) {
    console.error("Error loading categories and subcategories:", error);
    throw error;
  }
};




export const loadDiscountBundleAndQuery = async () => {
  try {
    const metadataRef = doc(getFirestore(), 'metadata', 'lastDiscountUpdate');
    const metadataDoc = await getDoc(metadataRef);
    const lastUpdate = metadataDoc.exists() ? metadataDoc.data().lastModifiedDateTime : null;

    const cachedBundleTimestamp = localStorage.getItem("lastDiscountBundleLoadTimestamp");
    let shouldReloadBundle = !cachedBundleTimestamp || new Date(cachedBundleTimestamp) < new Date(lastUpdate);

    if (shouldReloadBundle) {
      const bundleRef = ref(storage, "bundles/discountsBundle.bundle");
      const url = await getDownloadURL(bundleRef);
      const response = await fetch(url);
      const bundleBuffer = await response.arrayBuffer();
      await loadBundle(getFirestore(), bundleBuffer);
      localStorage.setItem("lastDiscountBundleLoadTimestamp", lastUpdate);
    }

    const query = await namedQuery(getFirestore(), "discounts-query");
    if (query) {
      const snapshot = await getDocsFromCache(query);
      return snapshot.docs.map(doc => doc.data());
    }
    return [];
  } catch (error) {
    console.error("Error loading discounts bundle:", error);
    throw error;
  }
};

// Initialize Performance Monitoring and get a reference to the service
export const perf = getPerformance(app);

export const authentication = getAuth(app);
export const storage = getStorage(app);
