import React, { createContext, useContext, useState, useEffect } from 'react';
import { authentication, db } from '../firebase/firebase';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { encryptData, decryptData } from '../services/cryptoUtils'; // Importera dina utility-funktioner

const UserContext = createContext();
export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [discountInfo, setDiscountInfo] = useState(null);
    const [role, setRole] = useState(null);
    const [supplierId, setSupplierId] = useState(null);

    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY; // Detta hämtas från din .env-fil

  

    useEffect(() => {
        const unsubscribe = authentication.onAuthStateChanged(async (userAuth) => {
            if (userAuth) {
                setUser(userAuth);
                const userDataRef = doc(db, 'customers', userAuth.uid);
                const userDataSnapshot = await getDoc(userDataRef);
                if (userDataSnapshot.exists()) {
                    const data = userDataSnapshot.data();
                    const discountPercent = await checkDiscounts(data.number);
                    const updatedData = { ...data, discount: discountPercent };
                    
                    // Kryptera användaruppgifter innan de sparas i sessionStorage
                    sessionStorage.setItem('userData', encryptData(updatedData));
                    
                    setUserData(updatedData);
                    setRole(updatedData.role);
                    setSupplierId(updatedData.supplierId);
                }
            } else {
                // Nollställ data och ta bort från sessionStorage vid utloggning
                setUser(null);
                setUserData(null);
                setDiscountInfo(null);
                setRole(null);
                setSupplierId(null);
                sessionStorage.removeItem('userData');
            }
        });

       // Dekryptera och ladda in användaruppgifter från sessionStorage när komponenten monteras
       const storedUserData = sessionStorage.getItem('userData');
       if (storedUserData) {
           const decryptedData = decryptData(storedUserData);
           setUserData(decryptedData);
           setRole(decryptedData.role);
           setSupplierId(decryptedData.supplierId);
       }

       return unsubscribe;
   }, []);

    const checkDiscounts = async (userNumber) => {
        const discountsRef = collection(db, 'discounts');
        const discountsSnapshot = await getDocs(discountsRef);
        let discountPercent = 0;

        discountsSnapshot.forEach(doc => {
            const data = doc.data();
            if (data.customers) {
                const customerIds = data.customers.map(customer => customer.customer);

                if (customerIds.includes(userNumber)) {
                    discountPercent = getDiscountPercentFromCode(data.discountCode);
                    setDiscountInfo({ discountId: doc.id, description: data.description, discountPercent });
                    return;
                }
            }
        });
        return discountPercent;
    };

    const getDiscountPercentFromCode = (discountCode) => {
        switch (discountCode) {
            case "03":
                return 3;
            case "05":
                return 5;
            case "06":
                return 6;
            case "07":
                return 7;
            case "08":
                return 8;
            case "10":
                return 10;
            case "11":
                return 11;
            case "12":
                return 12;
            case "15":
                return 15;
            case "30":
                return 30;
            case "CUSTOM15":
                return 15;
            default:
                return 0;
        }
    };

    return (
        <UserContext.Provider value={{ userData, setUserData, user, discountInfo, role, supplierId }}>
            {children}
        </UserContext.Provider>
    );
};
