import React, { useEffect, useState, useRef } from 'react';
import { useProductContext } from '../context/ProductContext';
import { Link, useNavigate } from 'react-router-dom';
import { searchProducts } from '../services/searchUtils'; // Importera search-funktionen

const SearchResults = () => {
    const { products, searchQuery, setSearchQuery } = useProductContext();
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();
    const resultsRef = useRef(null); // Skapa en referens för sökresultatdiven

    useEffect(() => {
        if (searchQuery) {
            // Använd den gemensamma söklogiken
            const results = searchProducts(products, searchQuery);
            setSearchResults(results.slice(0, 20)); // Begränsa till 20 resultat
        } else {
            setSearchResults([]);
        }
    }, [searchQuery, products]);


       // Hantera klick utanför rutan
       useEffect(() => {
        const handleClickOutside = (event) => {
            if (resultsRef.current && !resultsRef.current.contains(event.target)) {
                setSearchResults([]); // Stäng sökresultaten
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [resultsRef]);

    // Hantera "Enter"-tangenttryck
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                setSearchResults([]); // Stäng sökresultaten
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div ref={resultsRef} className='absolute w-full top-20 flex-col flex max-h-72 overflow-scroll search-results'>
            {searchResults.length > 0 && (
                <table className='w-full text-sm text-left font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white'>
                    <thead>
                        <tr>
                            <th className='px-4 py-2 border-b border-gray-200 dark:border-gray-600'>Produkt</th>
                            <th className='px-4 py-2 border-b border-gray-200 dark:border-gray-600'>Kategori</th>
                          
                        </tr>
                    </thead>
                    <tbody>
                        {searchResults.map(product => (
                            <tr key={product.inventoryNumber} className='border-b border-gray-200 dark:border-gray-600'>
                                <td className='px-4 py-2'><Link to={`/cat/${product.salesCategories[0].slug}/product/${product.inventoryNumber}`} onClick={() => setSearchQuery('')}>
                                    {product.description}
                                </Link></td>
                                <td className='px-4 py-2'>
                                    {product.salesCategories && product.salesCategories.length > 0 && (
                                        <Link to={`/cat/${product.salesCategories[0].slug}`} onClick={() => setSearchQuery('')}>
                                            {product.salesCategories[0].description}
                                        </Link>
                                    )}
                                </td>
                              
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default SearchResults;
