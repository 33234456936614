import React, { useState } from 'react';
import axios from 'axios';

const ResetPassword = () => {

    const sendPasswordResetEmail = async (email) => {
        try {
          const response = await axios.post('https://sendpasswordresetemail-l4rhowcthq-uc.a.run.app', { email });
          console.log(response.data);
          alert('Password reset email sent. Check your inbox.');
        } catch (error) {
          console.error('Error sending password reset email:', error);
          alert('Failed to send password reset email. Please try again later.');
        }
      };


  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    sendPasswordResetEmail(email);
  };

  return (
    <div className='container w-full md:w-1/2 mx-auto p-8 mt-64 md:mt-20'>
      <h1 className='text-4xl dark:text-gray-100 font-extrabold'>Återställ ditt lösenord</h1>
    <form className='container' onSubmit={handleSubmit}>
      <label className='my-3 block mb-2 text-sm dark:text-white font-medium text-gray-900 dark:text-white"'>
        Vänligen ange din e-postadress
        <input className='dark:bg-transparent dark:text-gray-400 dark:ring-gray-600 block w-full rounded-md border-0 py-2.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-transparent dark:border-gray-600'
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </label>
      <button className=' my-3 text-lg rounded-md bg-emerald-600	 px-3 py-2 text-sm font-semibold text-white shadow-sm   hover:bg-emerald-700' type="submit">Skicka återställningsmail</button>
    </form>
    </div>
  );
};

export default ResetPassword;
