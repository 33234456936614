import React, { useContext, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FaXmark } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { CartContext } from '../context/cart';

export default function Cart() {
  const { cartItems, addToCart, bestItems, nonBestItems, removeFromCart, getShippingStatus, getNonBestItemsTotal, getBestItemsTotal, getTotal, isCartOpen, toggleCart, setIsCartOpen, campaignDiscount, isCombinedDelivery, setIsCombinedDelivery } = useContext(CartContext);
  const placeholderImageUrl = 'https://via.placeholder.com/350';

 
  // Använd korrekt total för fraktfri-beräkning beroende på om samleverans är aktiv
  const cartTotalForShipping = isCombinedDelivery ? getTotal() : getNonBestItemsTotal();
  const { amountRemaining, percentage, isFreeShipping } = getShippingStatus(cartTotalForShipping);

  const formattedAmountRemaining = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(amountRemaining);

  // Slå samman alla produkter om samleverans är ikryssad
  const mergedItems = isCombinedDelivery ? [...nonBestItems, ...bestItems] : null;
  
  return (
    <Transition.Root show={isCartOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 lg:z-10" onClose={toggleCart}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex pt-8 lg:pt-36 h-full flex-col overflow-y-scroll bg-white dark:bg-gray-800 dark:text-white shadow-xl z-40">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-white">
                          Din varukorg:
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative -m-2 p-2 text-gray-400 dark:text-white hover:text-gray-500"
                            onClick={() => setIsCartOpen(false)}
                          >
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Close panel</span>
                            <FaXmark className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8 py-6">
                        <div className="flow-root">
                          {/* Rendera antingen alla produkter eller separera beroende på samleverans */}
                          <ul className="-my-6 divide-y divide-gray-200 dark:divide-gray-600">

                            {/* Om samleverans är aktiverad, rendera alla produkter utan rubriker */}
                            {isCombinedDelivery && mergedItems.map((item) => {
                              const imageSrc =
                                item.productThumbnails && item.productThumbnails.length > 0
                                  ? item.productThumbnails[0]
                                  : item.productImages && item.productImages.length > 0
                                  ? item.productImages[0]
                                  : placeholderImageUrl;

                              const itemPrice =
                                item.discountedPrice !== null && item.discountedPrice !== undefined
                                  ? item.discountedPrice
                                  : item.defaultPrice;

                                  const isDiscounted = item.discountedPrice !== null && item.discountedPrice !== undefined;
                                  const isKlipp = item.expirationDate !== null && item.expirationDate !== undefined ? true : false;
                                  const priceClass = isKlipp || isDiscounted ? 'text-red-600 underline' : '';
                              return (
                                <li className="flex py-6" key={item.inventoryNumber}>
                                
                                  <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 dark:border-gray-600">
                                    <img
                                      src={imageSrc}
                                      alt={item.description}
                                      className="h-full w-full object-cover object-center"
                                    />
                                  </div>
                                 
                                  <div className="ml-4 flex flex-1 flex-col">
                                  {isKlipp && (
      <div className='klippvara text-xs font-semibold text-red-700'>KLIPPVARA</div>
    )}
                                    <div>
                                  
                                      <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                                     
                                        <h3>
                                          <a href="#">{item.description}</a>
                                        </h3>
                                        <p className={`ml-4 ${priceClass}`}>
      {new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: 'SEK',
      }).format(itemPrice)}
    </p>
                                      </div>
                                    </div>
                                    <div className="flex gap-4">
                                      <button
                                        className="px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                        onClick={() => {
                                          removeFromCart(item);
                                        }}
                                      >
                                        -
                                      </button>
                                      <p>{item.quantity}</p>
                                      <button
                                        className="px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                        onClick={() => {
                                          addToCart(item);
                                        }}
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}

                            {/* Om inte samleverans är aktiverad, rendera separata listor med rubriker */}
                            {!isCombinedDelivery && (
                              <>
                                {/* Rubrik för vanliga produkter */}
                              {/* Rubrik för beställningsvaror */}
{bestItems.length > 0 && (
  <h2 className="text-lg font-medium text-gray-900 dark:text-white mt-6">
                                  Order:
                                </h2>
)}  
                                {nonBestItems.map((item) => {
                                  const imageSrc =
                                    item.productThumbnails && item.productThumbnails.length > 0
                                      ? item.productThumbnails[0]
                                      : item.productImages && item.productImages.length > 0
                                      ? item.productImages[0]
                                      : placeholderImageUrl;

                                  const itemPrice =
                                    item.discountedPrice !== null && item.discountedPrice !== undefined
                                      ? item.discountedPrice
                                      : item.defaultPrice;
                                      const isDiscounted = item.discountedPrice !== null && item.discountedPrice !== undefined;
                                      const isKlipp = item.expirationDate !== null && item.expirationDate !== undefined ? true : false;
                                      const priceClass = isKlipp || isDiscounted ? 'text-red-600 underline' : '';

                                  return (
                                    <li className="flex py-6" key={item.inventoryNumber}>
                                      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 dark:border-gray-600">
                                        <img
                                          src={imageSrc}
                                          alt={item.description}
                                          className="h-full w-full object-cover object-center"
                                        />
                                      </div>
                                      <div className="ml-4 flex flex-1 flex-col">
                                      {isKlipp && (
      <div className='klippvara text-xs font-semibold text-red-700'>KLIPPVARA</div>
    )}
                                        <div>
                                          <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                                            <h3>
                                              <a href="#">{item.description}</a>
                                            </h3>
                                             
                                            <p className={`ml-4 ${priceClass}`}>
      {new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: 'SEK',
      }).format(itemPrice)}
    </p>
                                          </div>
                                        </div>
                                        <div className="flex gap-4">
                                          <button
                                            className="px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                            onClick={() => {
                                              removeFromCart(item);
                                            }}
                                          >
                                            -
                                          </button>
                                          <p>{item.quantity}</p>
                                          <button
                                            className="px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                            onClick={() => {
                                              addToCart(item);
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
{/* Rubrik för beställningsvaror */}
{bestItems.length > 0 && (
  <h2 className="text-lg font-medium text-gray-900 dark:text-white mt-6">
    Beställningsvaror:
  </h2>
)}
                                {bestItems.map((item) => {
                                  const imageSrc =
                                    item.productThumbnails && item.productThumbnails.length > 0
                                      ? item.productThumbnails[0]
                                      : item.productImages && item.productImages.length > 0
                                      ? item.productImages[0]
                                      : placeholderImageUrl;

                                  const itemPrice =
                                    item.discountedPrice !== null && item.discountedPrice !== undefined
                                      ? item.discountedPrice
                                      : item.defaultPrice;

                                  return (
                                    <li className="flex py-6" key={item.inventoryNumber}>
                                      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 dark:border-gray-600">
                                        <img
                                          src={imageSrc}
                                          alt={item.description}
                                          className="h-full w-full object-cover object-center"
                                        />
                                      </div>
                                      <div className="ml-4 flex flex-1 flex-col">
                                        <div>
                                          <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                                            <h3>
                                              <a href="#">{item.description}</a>
                                            </h3>
                                            <p className="ml-4">
                                              {new Intl.NumberFormat('sv-SE', {
                                                style: 'currency',
                                                currency: 'SEK',
                                              }).format(itemPrice)}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="flex gap-4">
                                          <button
                                            className="px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                            onClick={() => {
                                              removeFromCart(item);
                                            }}
                                          >
                                            -
                                          </button>
                                          <p>{item.quantity}</p>
                                          <button
                                            className="px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                            onClick={() => {
                                              addToCart(item);
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                     
                      <div className="border-t border-gray-200 dark:border-gray-600 px-4 py-6 sm:px-6">
                      {bestItems.length > 0 && (
  <div className="flex items-center mt-6 my-6">
    <input
      id="combinedDelivery"
      type="checkbox"
      checked={isCombinedDelivery}
      onChange={(e) => setIsCombinedDelivery(e.target.checked)}
      className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded"
    />
    <label htmlFor="combinedDelivery" className="ml-3 block text-sm text-gray-900 dark:text-white">
      Önskar samleverans?
    </label>
  </div>
)}{!isCombinedDelivery ? (
                          <div>
                            {/* Visa separat total för order och beställningsorder */}
                            <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                              <span>Order Total</span>
                              <span>
                                {new Intl.NumberFormat('sv-SE', {
                                  style: 'currency',
                                  currency: 'SEK',
                                }).format(getNonBestItemsTotal())}
                              </span>
                            </div>
                            {bestItems.length > 0 && (
                              <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white mt-4">
                                <span>Beställningsorder Total</span>
                                <span>
                                  {new Intl.NumberFormat('sv-SE', {
                                    style: 'currency',
                                    currency: 'SEK',
                                  }).format(getBestItemsTotal())}
                                </span>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                            {/* Visa sammanslagen total */}
                            <span>Total</span>
                            <span>
                              {new Intl.NumberFormat('sv-SE', {
                                style: 'currency',
                                currency: 'SEK',
                              }).format(getTotal())}
                            </span>
                          </div>
                        )}

                        {campaignDiscount > 0 && (
                          <div className="mt-4">
                            <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                              Kampanjrabatt: -{' '}
                              {new Intl.NumberFormat('sv-SE', {
                                style: 'currency',
                                currency: 'SEK',
                              }).format(campaignDiscount)}
                            </span>
                          </div>
                        )}

                        <div className="mt-4">
                          <p
                            className={
                              isFreeShipping
                                ? 'inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700'
                                : ''
                            }
                          >
                            {isFreeShipping
                              ? 'FRAKTFRITT!'
                              : `Kvar till fraktfritt: ${formattedAmountRemaining}`}
                          </p>

                          {!isFreeShipping && (
                            <div className="w-full bg-gray-200 rounded-full h-2.5">
                              <div
                                className="bg-red-600 h-2.5 rounded-full transition-all ease-in-out"
                                style={{ width: `${percentage}%` }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="m-6">
                        <Link
                          to="/checkout"
                          onClick={() => setIsCartOpen(false)}
                          className="flex items-center justify-center rounded-md border border-transparent bg-red-700 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                        >
                          Till Kassan
                        </Link>
                      </div>
                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500 pb-12">
                        <p>
                          eller{' '}
                          <button
                            type="button"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                            onClick={() => setIsCartOpen(false)}
                          >
                            fortsätt handla
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
