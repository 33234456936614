import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../context/cart';
import { Link, useParams } from 'react-router-dom';
import { useUserContext } from '../context/userContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const Card = ({ product, klippvarorData }) => {
  const { mainCategorySlug } = useParams();

  const { cartItems, addToCart, removeFromCart, setIsProductListUpdate  } = useContext(CartContext);
  const { user } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);

  const { discountType, discountValue } = product;
 // Kontrollera om FRP-attributet finns i produkten eller i klippvarorData
 const frpAttribute = product.attributes.find(attribute => attribute.id === "FRP") ||
 klippvarorData?.attributes?.find(attribute => attribute.id === "FRP");
const viktAttribute = product.attributes.find(attribute => attribute.id === "VIKT");
const bestVara = product.attributes.find(attribute => attribute.id === "BEST" && attribute.value === "1");
  const formattedPrice = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(product.defaultPrice);
  const frpValue = frpAttribute ? frpAttribute.value : null;
  const viktValue = viktAttribute ? viktAttribute.value : null;
const nyhet = product.salesCategories.find(category => category.slug === 'nyheter');
 


  
  const getColorString = (slug) => {
    switch(slug) {
      case 'kex-brod-kakor':
        return 'bg-kexbrodkakor';
      case 'antipasto':
        return 'bg--antipasto';
        case 'olivolja-balsamico-vinager':
        return 'bg-olivoljabalsamicovinager';
            case 'pasta-ris-soppor':
        return 'bg-pastarissoppor';
            case 'kryddor-salt-smaksattare':
        return 'bg-kryddorsaltsmaksattare';
            case 'saser-dressing-bbq':
        return 'bg-saserdressingbbq';
             case 'marmelad-honung-curd':
        return 'bg-marmeladhonungcurd';
            case 'varma-drycker':
        return 'bg-varmadrycker';
            case 'dryck':
        return 'bg-dryck';
            case 'chips-snacks':
        return 'bg-chipssnacks';
        case 'konfektyr':
            return 'bg-konfektyr';
     
      default:
        return 'bg-defaultcolor';
    }
  };


  const colorString = getColorString(mainCategorySlug);

  const handleNavigation = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
  };

  const getDiscountedPriceToShow = () => {
    if (klippvarorData?.discountedPrice !== undefined) {
        return klippvarorData.discountedPrice;
    } else if (product?.discountedPrice !== undefined) {
        return product.discountedPrice;
    } else {
        return null;
    }
};

const formattedDiscountedPriceToShow = getDiscountedPriceToShow();

const formattedDiscountedPrice = formattedDiscountedPriceToShow
    ? new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(formattedDiscountedPriceToShow)
    : null;

  const incrementValue = (frpValue ? parseInt(frpValue) : 1); 

  const getStockStatus = (available, incrementValue) => {
      if (available <= 0 ) {
          return <span className="inline-flex items-center rounded-md bg-red-100 px-1.5 py-0.5 text-xs font-medium text-red-700">Slut i lager</span>;
      } else if (available <= incrementValue * 3) {
          return <span className="inline-flex items-center rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800"> {"Färre än"} {incrementValue * 3} {" i lager"}  </span>;
      } else {
          return <span className="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">I lager</span>;
      }
  };

  const placeholderImageUrl = 'https://via.placeholder.com/350';
// Funktion för att hitta en bild som innehåller "1024px" i filnamnet
const findImageWithResolution = (images) => {
  if (!images || images.length === 0) return null;
  return images.find(image => image.includes("1024px"));
};

// Hitta bilden med "1024px" i filnamnet, eller använd fallback-logik
const imageSrc = findImageWithResolution(product.productThumbnails) 
  || findImageWithResolution(product.productImages) 
  || product.productThumbnails?.[0] 
  || product.productImages?.[0] 
  || placeholderImageUrl;

useEffect(() => {
  const image = new Image();
  image.src = imageSrc;
  image.onload = () => setIsLoading(false);
}, [imageSrc]);
  const getPrefixedInventoryNumber = (product, klippvarorData) => {
    // Om klippvarorData finns, använd dess inventoryNumber med "-KLIPP"
    if (klippvarorData) {
        return `${product.inventoryNumber}-KLIPP`;
    }
    // Om det inte är en klippvara, använd bara det vanliga inventoryNumber
    return product.inventoryNumber;
};

useEffect(() => {
    // Lyssna på förändringar i varukorgen
    const isItemInCart = cartItems.find(item => 
      item.inventoryNumber === getPrefixedInventoryNumber(product, klippvarorData)
    );
  
    // Om produkten finns i varukorgen, uppdatera tillståndet för att visa plus/minus
    if (isItemInCart) {
      // Logik för att uppdatera komponentens tillstånd eller tvinga omrendering
    }
  }, [cartItems, product, klippvarorData]); // Lyssna på varukorgen, produkten och klippvarorData
  

const handleAddToCart = (product) => {
  setIsProductListUpdate(true);  // Markera att uppdateringen sker från produktlistan
    const prefixedInventoryNumber = getPrefixedInventoryNumber(product, klippvarorData);
    const isItemInCart = cartItems.find(item => item.inventoryNumber === prefixedInventoryNumber);
    
    let incrementValue;
  
    if (isItemInCart) {
      // Om produkten redan finns i varukorgen, använd cartItem.attributes för att hämta FRP-attributet
      const cartFrpAttribute = isItemInCart.attributes?.find(attribute => attribute.id === "FRP");
      incrementValue = cartFrpAttribute ? parseInt(cartFrpAttribute.value) : 1;
    } else {
      // Om produkten inte finns i varukorgen, använd product.attributes för att hämta FRP-attributet
      const productFrpAttribute = product.attributes?.find(attribute => attribute.id === "FRP");
      incrementValue = productFrpAttribute ? parseInt(productFrpAttribute.value) : 1;
    }
  
    const productToAdd = { 
      ...product, 
      inventoryNumber: prefixedInventoryNumber, 
      discountedPrice: formattedDiscountedPriceToShow 
    };
    addToCart(productToAdd, klippvarorData?.lotSerialNumber, klippvarorData?.expirationDate, incrementValue, formattedDiscountedPriceToShow);
  };
  
  const handleRemoveFromCart = (product) => {
    const prefixedInventoryNumber = getPrefixedInventoryNumber(product, klippvarorData);
    const isItemInCart = cartItems.find(item => item.inventoryNumber === prefixedInventoryNumber);
  
    let incrementValue = 1; // Fallback-värde
    if (isItemInCart) {
      // Använd cartItem.attributes för att hämta FRP-attributet om produkten redan finns i varukorgen
      const cartFrpAttribute = isItemInCart.attributes?.find(attribute => attribute.id === "FRP");
      incrementValue = cartFrpAttribute ? parseInt(cartFrpAttribute.value) : 1;
    }
  
    removeFromCart({ ...product, inventoryNumber: prefixedInventoryNumber }, incrementValue);
  };


  let supplierName = '';
  if (product.supplierDetails && product.supplierDetails.length > 0) {
      supplierName = product.supplierDetails[0].supplierName;
  }
  const supplierLink = `/supplier/${encodeURIComponent(supplierName)}`;

  return (
      <div id={`product-${product.inventoryNumber}`} className="card p-4 exclude-sidebar border border-gray-200 dark:border-gray-700 m-2 shadow dark:text-white relative" key={product.inventoryNumber}>
           {nyhet && (
                  <span className="inline-flex m-auto items-center rounded-md bg-gray-800 px-2 py-1 text-xs font-medium text-white">
                      Nyhet!
                  </span>
              )}
                 {bestVara && user && (
                  <span className=" mx-2  inline-flex items-center rounded-md bg-orange-400  px-2 py-1 text-xs font-medium text-orange-800">
                      Beställningsvara
                  </span>
              )}
          {supplierName && (
              <div className='supplier-name px-5'>
                  <Link to={supplierLink}>
                      {isLoading ? <Skeleton width={100} /> : supplierName}
                  </Link>
              </div>
          )}
          
          <div className={`flex relative rounded-lg h-full dark:bg-gray-800 p-2 flex-col ${isLoading ? 'opacity-50' : ''}`}>
              {product.discountCodes && product.discountCodes.length > 0 || (discountType && discountValue > 0) && (
                  <span className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
                      KAMPANJ
                  </span>
              )}
             
         
              {klippvarorData?.lotSerialNumber ? (
                  <span className='absolute z-10 top-0 left-0 m-2 rounded-md bg-red-100 px-1.5 py-0.5 text-xs font-medium text-red-700'>BF: {klippvarorData.expirationDate}</span>
              ) : null}
              <Link to={`/cat/${mainCategorySlug}/product/${product.inventoryNumber}`} className='relative mx-1 mt-3 flex h-60 rounded-xl justify-center' onClick={handleNavigation}>
                 {/* Villkorlig rendering för rabatt baserat på discountType */}
      {discountType && discountValue > 0 && (
        <div className="mt-2">
          {discountType === 'percentage' ? (
            <span className="font-semibold text-sm p-2  bg-red-700 bottom-2	text-white rounded absolute z-50 ">-{discountValue}%</span>
          ) : (
            <span className="font-semibold text-sm p-2  bg-red-700 bottom-2	text-white rounded absolute z-50 ">-{discountValue} kr</span>
          )}
        </div>
      )}
                  {isLoading ? <Skeleton height={240} width={350} /> : <img src={imageSrc} alt={product.description} className="card-img object-contain" />}
              </Link>
             
              <div className="card-details mt-4 px-5 pb-5 dark:text-white">
                  <h3 className="card-title text-md text-slate-900 dark:text-white">
                      {isLoading ? <Skeleton width={200} /> : product.description}
                  </h3>
                  <div className="card-productdetail text-sm text-slate-900 dark:text-white">
                      <p>{isLoading ? <Skeleton width={150} /> : `${product.inventoryNumber}`}</p>
                      {user && !isLoading ? (
              <>
                  <div className="card-price mt-2 mb-5 flex items-start justify-between flex-col ">
                      {formattedDiscountedPrice ? (
                          <>
                              <div className='text-2xl font-bold text-red-900 dark:text-white'>{formattedDiscountedPrice}</div>
                              <div className="text-sm text-slate-900 line-through">
                                  {formattedPrice}
                              </div>
                          </>
                      ) : (
                          <div className='text-2xl font-bold text-slate-900 dark:text-white'>{formattedPrice}</div>
                      )}
                  </div>
                {/* Rendera olika värden beroende på om klippvarorData finns */}
  {klippvarorData ? (
    <div className='stock'>
      {getStockStatus(klippvarorData.quantity, incrementValue)} 
      {/* Om du vill lägga till mer info baserat på klippvarorData */}
     
    </div>
  ) : (
    <div className='stock'>
      {getStockStatus(product.warehouseDetails[0].quantityOnHand, incrementValue)}
    </div>
  )}
              </>
          ) : null}
                      <div className='mt-6 flex justify-between items-center'>
                      {user ? (
  !cartItems.find(item => 
    item.inventoryNumber === getPrefixedInventoryNumber(product, klippvarorData)
  ) ? (
    <button
      className={`${colorString}  px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700 dark:bg-gray-700`}
      onClick={() => handleAddToCart(product)}
      disabled={isLoading}
    >
      Lägg i varukorg
    </button>
  ) : (
    <div className="flex gap-4">
      <button
        className="px-4 py-2 bg-red-400/75 text-white text-xs font-bold uppercase rounded hover:bg-red-700 focus:outline-none focus:bg-red-700"
        onClick={() => handleRemoveFromCart(product)}
        disabled={isLoading}
      >
        -
      </button>
      <p className='text-gray-600 dark:text-white'>
        {cartItems.find(item => 
          item.inventoryNumber === getPrefixedInventoryNumber(product, klippvarorData)
        ).quantity}
      </p>
      <button
        className="px-4 py-2 bg-green-400/75 text-white text-xs font-bold uppercase rounded hover:bg-green-700 focus:outline-none focus:bg-green-700"
        onClick={() => handleAddToCart(product)}
        disabled={isLoading}
      >
        +
      </button>
    </div>
  )
) : (
    <Link
        to={`/cat/${mainCategorySlug}/product/${product.inventoryNumber}`}
        className="px-4 py-2 bg-golden text-white text-xs font-bold uppercase rounded hover:bg-golden/50 focus:outline-none focus:bg-blue-700"
        onClick={handleNavigation}
    >
        Läs mer
    </Link>
)}
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default Card;

