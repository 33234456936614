import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { db, storage, loadFirestoreBundleAndQuery, loadCategoryBundleAndQuery, loadDiscountBundleAndQuery } from '../firebase/firebase';
import { getStorage, ref, getDownloadURL, loadBundle } from "firebase/storage";
import { collection, getDocs, query, getDoc, doc, deleteDoc, writeBatch } from 'firebase/firestore';
import { useUserContext } from './userContext';
import { getDiscounts } from '../services/discounts';

const ProductContext = createContext();
export const useProductContext = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [klippvaror, setKlippvaror] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [kampanjProducts, setKampanjProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [discounts, setDiscounts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { discountInfo, userData } = useUserContext();
    const [mainCategories, setMainCategories] = useState([]);
    const [supplierData, setSupplierData] = useState([]);
    // Lägg till en state för promotionalContent
const [promotionalContent, setPromotionalContent] = useState([]);

const [discountedProducts, setDiscountedProducts] = useState([]);   
    const stringToSlug = (str) => {
        return str.toLowerCase()
            .replace(/[åä]/g, 'a')
            .replace(/[ö]/g, 'o')
            .replace(/[^a-zA-Z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-')
            .trim();
    };

    
// Funktion för att hämta promotionalContent från Firestore
const fetchPromotionalContent = async () => {
    try {
        const promotionsRef = collection(db, 'promotionalContent');
        const promotionSnapshot = await getDocs(promotionsRef);
        const promotionData = promotionSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        setPromotionalContent(promotionData); // Spara promotionalContent till state
        return promotionData; // Returnerar kampanjdatan
    } catch (error) {
        console.error('Error fetching promotionalContent:', error);
        return [];
    }
};


    const processUniqueSupplierData = (productsData) => {
        const supplierSet = new Set();
        return productsData.flatMap(product =>
            product.supplierDetails.map(supplier => {
                const supplierKey = `${supplier.supplierName}-${supplier.supplierId}`;
                if (!supplierSet.has(supplierKey)) {
                    supplierSet.add(supplierKey);
                    return {
                        supplierName: supplier.supplierName,
                        supplierId: String(supplier.supplierId)
                    };
                }
                return null;
            })
        ).filter(supplier => supplier !== null);
    };
    const fetchSubCategories = async (mainCategoryId) => {
        try {
          // Logga hela mainCategories för att se om datan finns korrekt
          console.log('Alla huvudkategorier:', mainCategories);
      
          // Hitta huvudkategorin baserat på mainCategoryId
          const mainCategory = mainCategories.find(category => category.id === mainCategoryId);
          
          // Logga vilken huvudkategori som hittades
          console.log('Vald huvudkategori:', mainCategory);
      
          if (!mainCategory) {
            throw new Error("Main category not found");
          }
      
          // Logga underkategorierna om de finns
          console.log('Underkategorier i huvudkategori:', mainCategory.subCategories);
      
          // Hämta underkategorierna om de finns
          const subCategories = mainCategory.subCategories || [];
      
          // Om det finns underkategorier, bearbeta dem
          const processedSubCategories = subCategories.map(subCat => ({
            id: subCat.categoryID,
            description: subCat.description,
            slug: stringToSlug(subCat.description),
            isActive: subCat.isActive || false
          }));
      
          return processedSubCategories;
        } catch (error) {
          console.error("Error fetching subcategories:", error);
          return [];
        }
      };

      // Function to fetch and apply customer-specific discounts
const applyCustomerDiscounts = async (productsData, userCustomerNumber) => {
  try {
      const discountsRef = collection(db, 'discounts');
      const discountsSnapshot = await getDocs(discountsRef);

      let applicableDiscount = null;

      // Gå igenom varje dokument i discounts-samlingen
      discountsSnapshot.docs.forEach(doc => {
          const discountData = doc.data();
          
          // Se till att 'customers' existerar och är en array
          if (Array.isArray(discountData.customers)) {
              // Kontrollera om något objekt i customers-arrayen innehåller det inloggade kundnumret
              if (discountData.customers.some(customerObj => customerObj.customer === userCustomerNumber)) {
                  applicableDiscount = discountData;
              }
          }
      });

      if (applicableDiscount) {
        console.log('Applicable discount:', applicableDiscount);
          const discountPercent = parseInt(applicableDiscount.discountCode, 10); // Convert discount code to percentage

          // Apply the discount to all products except promotionalDiscounts and klippvaror
          const updatedProducts = productsData.map(product => {
              if (!product.discountedPrice && !product.isKlippvara) {  // Ensure not already discounted
                  const discountedPrice = calculateDiscountedPrice(product.defaultPrice, discountPercent);
                  return { 
                      ...product, 
                      discountedPrice 
                  };
              }
              return product; // Return product unchanged if already discounted
          });

          return updatedProducts;
      }

      return productsData;  // If no applicable discount is found, return products unchanged
  } catch (error) {
      console.error('Error fetching and applying customer discounts:', error);
      return productsData;  // Return original products if error occurs
  }
};


      const processProducts = async (loadedProducts) => {
        return loadedProducts.map(product => {
            // Slå samman salesCategories och supplierDetails med slugs
            const salesCategoriesWithSlug = product.salesCategories.map(category => ({
                ...category,
                slug: stringToSlug(category.description)
            }));
            const supplierDetailsWithSlug = product.supplierDetails.map(supplier => ({
                ...supplier,
                slug: stringToSlug(supplier.supplierName)
            }));
    
            return {
                ...product,
                salesCategories: salesCategoriesWithSlug,
                supplierDetails: supplierDetailsWithSlug
            };
        }).filter(product => 
            product.status === 'Active' && 
            product.attributes.some(attribute => attribute.id === "PRODWEB" && attribute.value === "1")
        );};

    

    const calculateDiscountedPrice = (price, discountPercent) => {
        return price - (price * discountPercent / 100);
    };

    const removeExpiredProduct = async (productId) => {
        try {
            await deleteDoc(doc(db, 'klippvaror', productId));
            console.log(`Product with ID ${productId} has been removed.`);
        } catch (error) {
            console.error('Error removing product: ', error);
        }
    };

    const processKlippvaror = async (productsData) => {
      let klippvarorQuery = collection(db, 'klippvaror');
      const klippvarorSnapshot = await getDocs(klippvarorQuery);
      const klippvarorData = klippvarorSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
      }));
  
      const combinedData = klippvarorData.map(klippvara => {
          const matchingProduct = productsData.find(product => product.inventoryNumber === klippvara.inventoryItem.number);
  
          if (matchingProduct) {
              return {
                  klippvarorData: klippvara,
                  productData: matchingProduct
              };
          }
  
          return null;
      }).filter(Boolean);
  
      const updatedCombinedDataWithDiscount = [];
  
      for (const item of combinedData) {
          const expirationDate = new Date(item.klippvarorData.expirationDate);
          const today = new Date();
          const diffTime = expirationDate - today;
          const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
          // Kontrollera om quantity är <= 0 och radera produkten om så är fallet
          if (diffDays <= 0 || item.klippvarorData.quantity <= 0) {
              await removeExpiredProduct(item.klippvarorData.id);
          } else {
              let discountPercent = 0;
  
              if (diffDays >= 90) {
                  discountPercent = 30;
              } else if (diffDays >= 60) {
                  discountPercent = 50;
              } else if (diffDays >= 30) {
                  discountPercent = 70;
              } else if (diffDays > 0) {
                  discountPercent = 70;
              }
  
              const discountedPrice = Math.round(item.productData.defaultPrice * (1 - discountPercent / 100));
  
              updatedCombinedDataWithDiscount.push({
                  ...item,
                  klippvarorData: {
                      ...item.klippvarorData,
                      expirationDate: expirationDate.toISOString().split('T')[0],
                      discountedPrice: discountedPrice
                  }
              });
          }
      }
  
      console.log("Updated Combined Data with Discount: ", updatedCombinedDataWithDiscount);
      return updatedCombinedDataWithDiscount;
  };


 
    const fetchGeneralData = async () => {
        setLoading(true);
      
        try {
          const loadedProducts = await loadFirestoreBundleAndQuery();
          const processedProducts = await processProducts(loadedProducts);
      
          // Kolla om produkterna har ändrats
          if (JSON.stringify(products) !== JSON.stringify(processedProducts)) {
            setProducts(processedProducts);
          }
      
      
    // 3. Hämta och sätt leverantörsdata (fixad jämförelse)
    const newSupplierData = processUniqueSupplierData(processedProducts);
    if (JSON.stringify(newSupplierData) !== JSON.stringify(supplierData)) {
      setSupplierData(newSupplierData);  // Uppdatera state om leverantörsdata har ändrats
    }
          const loadedCategories = await loadCategoryBundleAndQuery();
          const newMainCategories = loadedCategories.map(category => ({
            id: category.categoryID,
            description: category.description,
            slug: stringToSlug(category.description),
            subCategories: category.subCategories ? category.subCategories.map(subCategory => ({
              ...subCategory,
              slug: stringToSlug(subCategory.description),
              description: subCategory.description
            })) : []
          }));
      
          if (JSON.stringify(mainCategories) !== JSON.stringify(newMainCategories)) {
            setMainCategories(newMainCategories);
          }
      
        } catch (error) {
          console.error("Error fetching general data:", error);
        } finally {
          setLoading(false);
        }
      };
      
      const fetchDiscountsAndKampanjProducts = async (productsData, promotionalContent, userCustomerNumber) => {
        const currentDate = new Date();
    
        // Apply promotional discounts from your business system
        const discountsList = await getDiscounts();
        const promotionalDiscounts = discountsList.filter(discount => {
            const effectiveDate = new Date(discount.effectiveDate);
            const expirationDate = new Date(discount.expirationDate);
            return discount.promotional && currentDate >= effectiveDate && currentDate <= expirationDate;
        });
    
        const updatedProducts = productsData.map(product => {
            let updatedProduct = { ...product };
    
            // Apply promotional discounts from your business system
            promotionalDiscounts.forEach(discount => {
                if (discount.items.some(item => item.itemId === product.inventoryNumber)) {
                    updatedProduct.discountCodes = updatedProduct.discountCodes || [];
                    updatedProduct.discountCodes.push(discount.discountCode);
                    updatedProduct.discountedPrice = calculateDiscountedPrice(product.defaultPrice, discount.discountValue);
                }
            });
    
            // Apply promotional content discounts
            promotionalContent.forEach(promo => {
                if (promo.products && Array.isArray(promo.products)) {
                    promo.products.forEach(promoProduct => {
                        if (promoProduct.inventoryNumber === product.inventoryNumber) {
                            updatedProduct.discountType = promoProduct.discountType;
                            updatedProduct.discountValue = promoProduct.discountValue;
                            updatedProduct.discountedPrice = promoProduct.discountedPrice;
                        }
                    });
                }
            });
    
            return updatedProduct;
        });
    
        // Apply customer-specific discounts (after promotional and klippvaror logic)
        const finalProducts = await applyCustomerDiscounts(updatedProducts, userCustomerNumber);
    
        return finalProducts;
    };
    
      
      useEffect(() => {
        const fetchGeneralAndUserData = async () => {
          const generalData = await fetchGeneralData();  // Fetch general product data
          if (userData) {
              const loadedPromotionalContent = await fetchPromotionalContent(); // Fetch promotional content
              console.log("Logged in user customer number: ", userData?.number);
              const updatedProducts = await fetchDiscountsAndKampanjProducts(products, loadedPromotionalContent, userData.number);  // Apply discounts and campaigns
              
              // Process klippvaror
              const klippvarorData = await processKlippvaror(updatedProducts);
              setKlippvaror(klippvarorData);
  
              setDiscountedProducts(updatedProducts);  // Set updated products with discounts and campaigns
          }
      };
  
      fetchGeneralAndUserData();
      }, [userData, products]); // Körs bara när userData eller produkter ändras

    const filterProducts = useCallback((mainCategorySlug, selectedSupplier, subCategorySlug, selectedAttributes) => {
        console.log("filterProducts function is called");
        console.log("mainCategorySlug:", mainCategorySlug);
        console.log("selectedSupplier:", selectedSupplier);
        console.log("subCategorySlug:", subCategorySlug);
        console.log("selectedAttributes:", selectedAttributes);
        console.log("discountedProducts:", discountedProducts);
        console.log("products:", products);
    
        // Använd `discountedProducts` om det finns, annars `products`
        const availableProducts = discountedProducts.length > 0 ? discountedProducts : products;
    
        return availableProducts.filter(product => {
            // Filter för säsongsprodukter
            if (!mainCategorySlug === 'Säsong' && !subCategorySlug === 'Säsong') {
                return !product.salesCategories.some(salesCategory => salesCategory.slug === 'Säsong');
            }
    
            let matchesCategory = true;
            let matchesSupplier = true;
            let matchesAttributes = true;
    
            // Filtrering baserat på kategori och leverantör
            if (mainCategorySlug && subCategorySlug && selectedSupplier) {
                matchesCategory = product.salesCategories.some(salesCategory => salesCategory.slug === mainCategorySlug) &&
                                  product.salesCategories.some(salesCategory => salesCategory.slug === subCategorySlug);
                matchesSupplier = product.supplierDetails.some(supplier => supplier.supplierName === selectedSupplier);
            } else if (mainCategorySlug && subCategorySlug) {
                matchesCategory = product.salesCategories.some(salesCategory => salesCategory.slug === mainCategorySlug) &&
                                  product.salesCategories.some(salesCategory => salesCategory.slug === subCategorySlug);
            } else if (mainCategorySlug && selectedSupplier) {
                matchesCategory = product.salesCategories.some(salesCategory => salesCategory.slug === mainCategorySlug);
                matchesSupplier = product.supplierDetails.some(supplier => supplier.supplierName === selectedSupplier);
            } else if (subCategorySlug) {
                matchesCategory = product.salesCategories.some(salesCategory => salesCategory.slug === subCategorySlug);
            } else if (mainCategorySlug) {
                matchesCategory = product.salesCategories.some(salesCategory => salesCategory.slug === mainCategorySlug);
            } else if (selectedSupplier) {
                matchesSupplier = product.supplierDetails.some(supplier => supplier.supplierName === selectedSupplier);
            }
    
            // Filtrering baserat på produktattribut
            if (Array.isArray(selectedAttributes) && selectedAttributes.length > 0) {
                matchesAttributes = selectedAttributes.every(attr => 
                    product.attributes && 
                    Array.isArray(product.attributes) && 
                    product.attributes.some(attribute => attribute.id === attr && attribute.value === '1')
                );
            }
    
            return matchesCategory && matchesSupplier && matchesAttributes;
        });
    }, [products, discountedProducts]);
    
    




    return (
        <ProductContext.Provider value={{ klippvaror, promotionalContent, mainCategories, products, subCategories, fetchSubCategories, searchQuery, setSearchQuery, supplierData, filterProducts, kampanjProducts, discounts, loading, discountedProducts }}>
            {children}
        </ProductContext.Provider>
    );
};
