// Uppdatera Category.js för att hämta och rendera underkategorier
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProductContext } from '../../context/ProductContext';
import { AiOutlineArrowRight, AiOutlineRight } from 'react-icons/ai';

const Category = ({handleChange, onClick}) => {
  const navigate = useNavigate();
  const { mainCategories, fetchSubCategories } = useProductContext();
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const handleMainCategoryClick = async (mainCategory) => {
    setSelectedMainCategory(mainCategory);
  
    // Logga underkategorierna för att se om de är tillgängliga
    console.log('Underkategorier för', mainCategory.description, mainCategory.subCategories);
  
    // Om underkategorierna finns i mainCategory direkt, sätt dem utan att göra ett nytt anrop
    if (mainCategory.subCategories && mainCategory.subCategories.length > 0) {
      setSubCategories(mainCategory.subCategories);
    } else {
      // Om du fortfarande vill använda fetchSubCategories om det behövs
      const fetchedSubCategories = await fetchSubCategories(mainCategory.id);
      setSubCategories(fetchedSubCategories);
    }
  
    navigate(`/cat/${mainCategory.slug}`);
    handleChange(); // Kör handleChange om det behövs för andra ändamål
    onClick(); // Stänger sidopanelen i mobilvy
  };

  const categorySortOrder = {
    "Antipasto": 4,
    "Kex, bröd & Kakor": 5,
    "Olivolja, balsamico & vinäger": 6,
    "Pasta, ris & soppor": 7,
    "Kryddor, salt & smaksättare": 8,
    "Såser, dressing & BBQ": 9,
    "Marmelad, honung & curd": 10,
    "Varma drycker": 11,
    "Dryck": 12,
    "Chips & snacks": 13,
    "Konfekt": 14,
    "Present": 3,
    "Säsong": 2,
    "Nyheter": 1
  };

  const sortedMainCategories = [...mainCategories].sort((a, b) => {
    const orderA = categorySortOrder[a.description] || 999;
    const orderB = categorySortOrder[b.description] || 999;
    return orderA - orderB;
  });

  const stripHyphens = (slug) => {
    return slug.replace(/-/g, '');
  };

  return (
    <nav className="flex flex-1 flex-col dark:text-white" aria-label="Sidebar">
      <h2 className="sidebar-title m-3 font-bold">Kategorier</h2>
      <ul className="space-y-2 font-medium">
        {sortedMainCategories.map((category) => (
          <li key={category.id}  onClick={() => handleMainCategoryClick(category)} className='border-b-2 dark:border-b-gray-600'>
            <button 
              className="sidebar-label-container flex items-center w-full justify-between text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
             
            >
              <span className={`ms-3 before:border-l-4 before:border-${stripHyphens(category.slug)}  before:mr-3`}>{category.description}</span>
              <AiOutlineRight size="10"/>
            </button>
            {selectedMainCategory && selectedMainCategory.id === category.id && subCategories.length > 0 && (
              <ul className="space-y-2 font-medium text-sm">
                {subCategories.map((subcategory) => (
                  <li key={subcategory.categoryID} className='border-b-2 dark:border-b-gray-600 pl-5'>
                    <button 
                      className="sidebar-label-container flex items-center p-2 text-gray-400 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                      onClick={() => {
                        navigate(`/cat/${selectedMainCategory.slug}/${subcategory.slug}`);
                        onClick();
                      }}
                    >
                      <span className="ms-3">{subcategory.description}</span>
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Category;
