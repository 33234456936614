// OrderDetails.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useUserContext } from '../../../context/userContext';

const OrderDetails = () => {
  const { currentUser } = useUserContext();
  const { orderId, displayName } = useParams(); // Hämta ordernumret från URL:en med useParams-hooken
  const [orderLines, setOrderLines] = useState([]);
  
  useEffect(() => {
    const fetchOrderLines = async () => {
      try {
        const response = await axios.get(`https://15101301.gastronomileverantoren.se/api/order/${orderId}/lines`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const sortedOrderLines = response.data.value.sort((a, b) => a.lineId - b.lineId);
        setOrderLines(sortedOrderLines);
        
      } catch (error) {
        console.error('Error fetching order lines:', error);
      }
    };

    fetchOrderLines();
  }, [orderId]);

  if (orderLines.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className='mt-10 pt-10 md:pt-0'>
      <nav className="flex p-5" aria-label="Breadcrumb">
        <ol className="inline-flex md:flex-row flex-col text-sm justify-start dark:text-gray-200 items-center mx-auto md:mx-0 space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li className="inline-flex items-center"><Link className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" to="/"><svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
          </svg>Hem</Link></li>
          <li>
            <div className="flex items-center">
              <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
              </svg>
              <Link to={`/user/${displayName}/user-profile`}>Profil</Link>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
              </svg>
              <Link to={`/user/${displayName}/order-history`}>Orderhistorik</Link>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
              </svg>
              <Link to={`/user/${currentUser}/order/${orderId}`}>Vald order {orderId}</Link>
            </div>
          </li>
        </ol>
      </nav>
      <div className="min-w-0 flex-1 mt-10 mb-5 ml-10 ">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 dark:text-gray-200 sm:truncate sm:text-3xl sm:tracking-tight">Orderdetaljer för Order: {orderId}</h2>
      </div>
      <div className="relative p-10 overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Radnummer</th>
              <th scope="col" className="px-6 py-3">Beskrivning:</th>
              <th scope="col" className="px-6 py-3">Antal:</th>
              <th scope="col" className="px-6 py-3">Enhetspris:</th>
            </tr>
          </thead>
          <tbody>
            {orderLines.map((line) => (
              <tr key={line.lineId} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {line.lineId}
                </th>
                <td className="px-6 py-4">{line.description}</td>
                <td className="px-6 py-4">{line.quantity} st</td>
                <td className="px-6 py-4">{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(line.unitPrice)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderDetails;
