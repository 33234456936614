import React from "react";
import { useProductContext } from '../context/ProductContext';
import SearchResults from "../components/SearchResults";

const Search = ({ handleInputChange, handleChange, query, handleSearchSubmit }) => {
  const { searchQuery, setSearchQuery } = useProductContext();

  const handleInputChangeLocal = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="w-full md:w-1/2 relative flex flex-col">
      <form onSubmit={(e) => e.preventDefault()} className="">
        <div className="relative w-auto">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <div className="search-container w-full">
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 ps-10 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Sök på leverantör, artikelnummer, produktnamn"
              value={searchQuery}
              onChange={handleInputChangeLocal}
              onKeyDown={handleSearchSubmit}
            />
          </div>
        </div>
      </form>
      {/* Visa SearchResults om en sökning har påbörjats */}
      {searchQuery.length >= 3 && <SearchResults />}
    </div>
  );
};

export default Search;
