// Layout.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faOliveBranch, faUtensils, faArrowDownFromArc, faBottleDroplet, faGrillHot, faCookie, faCandy, faBowlHot, faMugHot, faHoneyPot,faPepperHot, faOlive } from '@awesome.me/kit-e012a9aa4e/icons/classic/solid'
import ShowroomGuide from '../imgs/Showroomsguiden_Logotyp.png';
import Gastrologga from '../imgs/Gastronomileverantoren_logotyp.png';
import { Link } from 'react-router-dom';
import InstagramFeed from '../services/instagramFeed';

const Main = () => {
const antipastoimg = "https://storage.googleapis.com/gastrodatabas.appspot.com/categoryImage/antipasto.jpg";
const olivoljabalsamicovinagerimg = "https://storage.googleapis.com/gastrodatabas.appspot.com/categoryImage/olivolja-balsamico-vinager.jpg";
const chipssnacksimg = "https://storage.googleapis.com/gastrodatabas.appspot.com/categoryImage/chips-snacks.jpg";
const konfektyrimg = "https://storage.googleapis.com/gastrodatabas.appspot.com/categoryImage/konfektyr.jpg";
const kexbrodkakorimg = "https://storage.googleapis.com/gastrodatabas.appspot.com/categoryImage/kex-brod-kakor.jpg";
const pastarissopporimg = "https://storage.googleapis.com/gastrodatabas.appspot.com/categoryImage/pasta-ris-soppor.jpg";
const kryddorsaltsmaksattareimg = "https://storage.googleapis.com/gastrodatabas.appspot.com/categoryImage/kryddor-salt-smaksattare.jpg";
const saserdressingbbqimg = "https://storage.googleapis.com/gastrodatabas.appspot.com/categoryImage/saser-dressing-bbq.jpg";
const marmeladhonungcurdimg = "https://storage.googleapis.com/gastrodatabas.appspot.com/categoryImage/marmelad-honung-curd.jpg";
const varmadryckerimg = "https://storage.googleapis.com/gastrodatabas.appspot.com/categoryImage/varma-drycker.jpg"; 
const dryckimg = "https://storage.googleapis.com/gastrodatabas.appspot.com/categoryImage/dryck.jpg";
const presentimg = "";

  return (
    <div className="">
      <header
  className="relative flex items-center justify-center w-screen   h-fit-content max-h-[950px]"
>
  <div
    className="relative z-30 p-5 text-center text-white "
  >
   <img src={Gastrologga} alt="Gastronomileverantören logotyp" className="w-32 mx-auto shadow-lg" /> 
  <h1 className=' text-6xl py-2 font-bold'> God smak är den bästa gåvan!</h1>
  <h2 className='text-2xl py-2'>Gastronomileverantören är importör av premiumdelikatesser från hela världen.</h2>
  <p className='w-2/3 text-center mx-auto'>Vi tycker att god smak är den bästa gåvan. 
          Vårt mål är att leverera produkter av högsta kvalitet och med en underbar smak 
          som ger krydda åt livet. Med största omsorg har vi valt ut alla våra 
          produkter för att kunna erbjuda fantastiska smakupplevelser.</p>
  <div className='flex flex-col items-center '>
    <div className='flex items-center justify-center gap-10'>
    <Link to="/login" className='rounded-md bg-slate-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-forestgreen-300 focus-visible:outline focus-visible:outline-2 w-auto mx-auto my-5 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'>
    Logga in</Link>
    <Link to="/bli-aterforsaljare" className='rounded-md bg-green-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-forestgreen-300 focus-visible:outline focus-visible:outline-2 w-auto mx-auto my-5 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'>
      Bli återförsäljare</Link>
      </div>
  <span className='text-xl font-semibold mt-10'>Upptäck vårt sortiment!</span>
  <FontAwesomeIcon icon={faArrowDownFromArc} className='my-4' size="2xl" />
  </div>
  </div>
  
  <video
    autoPlay
    loop
    muted
    playsInline
    className="absolute z-10 object-cover md:w-full h-full brightness-50 "
  >
    <source
      src="https://storage.googleapis.com/gastrodatabas.appspot.com/market/bg-video.mp4"
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>
</header>
        
     
      <div className="container-full block   py-10  "> <div className='container
dark:text-white mx-auto p-8 text-center pt-20 flex flex-col justify-center flex-center gap-4'>
  <FontAwesomeIcon icon={faUtensils} size="2xl" className='text-gray-500' />
        <h2 className='text-2xl'>Ett brett sortiment av delikatessvaror för alla tillfällen.</h2><p className='container-md'>Med över 1000 varor i sortimentet kan vi erbjuda våra kunder delikatesser från hela gourmetbordet. 
          </p></div>
<div className='container dark:text-white mx-auto grid p-10 sm:p-0 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 items-start justify-start   text-center   gap-6 	'>
  <div className='antipasto flex flex-col w-full	justify-start content-center text-center gap-4 p-3 self-start text-white'>
<div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  hover:shadow-xl shadow-md'">
<Link to="cat/antipasto">
        <img className="rounded-t-lg max-h-48 w-full object-cover" src={antipastoimg} alt="" />
  
    <div className="p-5">
      
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex flex-col">
            Antipasto</h5>
      
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Äkta italiensk tryffel, franska patéer, pesto, tapenad, bruschetta och oliver.</p>
        <div className="inline-flex items-center px-3 py-2 text-sm  font-medium text-center underline underline-offset-2 text-slate-800 rounded-lg hover:text-antipasto focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-antipasto  dark:text-white dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Utforska Antipasto
             <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        
    </div>
</div>
</Link>
</div>


   </div>
   <div className='antipasto flex flex-col w-full	justify-start content-center text-center gap-4 p-3 self-start text-white'>
<div className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  hover:shadow-xl shadow-md'">
<Link to="cat/kex-brod-kakor">
        <img className="rounded-t-lg max-h-48 w-full object-cover" src={kexbrodkakorimg} alt="" />
  
    <div className="p-5">
      
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex flex-col">
            Kex, bröd & kakor</h5>
      
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Sweet and savoury! Crackers för ostbrickan, flatbreads, klassiska shortbread, kakor, cantuccini, maränger</p>
        <div className="inline-flex items-center px-3 py-2 text-sm  font-medium text-center  underline underline-offset-2 text-slate-800 rounded-lg hover:text-kexbrodkakor focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-kexbrodkakor  dark:text-white dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Utforska Kex, bröd & Kakor
             <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        
    </div>
</div>
</Link>
</div>


   </div>
   <div className='antipasto flex flex-col w-full	justify-start content-center text-center gap-4 p-3 self-start text-white'>
<div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  hover:shadow-xl shadow-md'">
<Link to="cat/olivolja-balsamico-vinager">
        <img className="rounded-t-lg max-h-48 w-full object-cover" src={olivoljabalsamicovinagerimg} alt="" />
  
    <div className="p-5">
      
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex flex-col">
            Olivolja, balsamico & vinäger</h5>
      
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">En av våra specialiteter! Här hittar du olivolja i flaskor, sprayer och krus samt balsamico & vinäger, glaze och smaksatta oljor.</p>
        <div className="inline-flex items-center px-3 py-2 text-sm  font-medium text-center  underline underline-offset-2 text-slate-800 rounded-lg hover:text-olivoljabalsamicovinager focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-olivoljabalsamicovinager  dark:text-white dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Utforska Olivolja, balsamico & vinäger
             <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        
    </div>
</div>
</Link>
</div>


   </div>
   <div className='antipasto flex flex-col w-full	justify-start content-center text-center gap-4 p-3 self-start text-white'>
<div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  hover:shadow-xl shadow-md'">
<Link to="cat/pasta-ris-soppor">
        <img className="rounded-t-lg max-h-48 w-full object-cover" src={pastarissopporimg} alt="" />
  
    <div className="p-5">
      
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex flex-col">
            Pasta, ris & soppor</h5>
      
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Värmande soppor, lyxpasta, risotto, paella och ris.</p>
        <div className="inline-flex items-center px-3 py-2 text-sm  font-medium text-center  underline underline-offset-2 text-slate-800 rounded-lg hover:text-kexbrodkakor focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-pastarissoppor  dark:text-white dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Utforska Pasta, ris & soppor
             <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        
    </div>
</div>
</Link>
</div>


   </div>
   <div className='antipasto flex flex-col w-full	justify-start content-center text-center gap-4 p-3 self-start text-white'>
<div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  hover:shadow-xl shadow-md'">
<Link to="cat/kryddor-salt-smaksattare">
        <img className="rounded-t-lg max-h-48 w-full object-cover" src={kryddorsaltsmaksattareimg} alt="" />
  
    <div className="p-5">
      
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex flex-col">
            Kryddor, salt & smaksättare</h5>
      
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Kryddor & smaksättare från världens alla hörn: Salt, kryddor, rubs och senap</p>
        <div className="inline-flex items-center px-3 py-2 text-sm  font-medium text-center  underline underline-offset-2 text-slate-800 rounded-lg hover:text-kryddorsaltsmaksattare focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-kryddorsaltsmaksattare  dark:text-white dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Utforska Kryddor, salt & smaksättare
             <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        
    </div>
</div>
</Link>
</div>


   </div>
   <div className='antipasto flex flex-col w-full	justify-start content-center text-center gap-4 p-3 self-start text-white'>
<div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  hover:shadow-xl shadow-md'">
<Link to="cat/saser-dressing-bbq">
        <img className="rounded-t-lg max-h-48 w-full object-cover" src={saserdressingbbqimg} alt="" />
  
    <div className="p-5">
      
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex flex-col">
            Såser, dressing & BBQ</h5>
      
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Oavsett om det är för grillen, grytan eller pastan hittar du här läckra BBQ-såser, dressingar, marinader, rubs, ketchup, och dressingar</p>
        <div className="inline-flex items-center px-3 py-2 text-sm  font-medium text-center  underline underline-offset-2 text-slate-800 rounded-lg hover:text-saserdressingbbq focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-saserdressingbbq  dark:text-white dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Utforska  Såser, dressing & BBQ
             <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        
    </div>
</div>
</Link>
</div>


   </div>
   <div className='antipasto flex flex-col w-full	justify-start content-center text-center gap-4 p-3 self-start text-white'>
<div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  hover:shadow-xl shadow-md'">
<Link to="cat/marmelad-honung-curd">
        <img className="rounded-t-lg max-h-48 w-full object-cover" src={marmeladhonungcurdimg} alt="" />
  
    <div className="p-5">
      
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex flex-col">
            Marmelad, Honung & Curd</h5>
      
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Sött och gott för dessert, ostbrickan och frukostbordet: honung, ostmarmelad, marmelader och curd.</p>
        <div className="inline-flex items-center px-3 py-2 text-sm  font-medium text-center  underline underline-offset-2 text-slate-800 rounded-lg hover:text-marmeladhonungcurd focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-marmeladhonungcurd dark:text-white dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Utforska Marmelad, Honung & Curd
             <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        
    </div>
</div>
</Link>
</div>


   </div>
   <div className='antipasto flex flex-col w-full	justify-start content-center text-center gap-4 p-3 self-start text-white'>
<div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  hover:shadow-xl shadow-md'">
<Link to="cat/varma-drycker">
        <img className="rounded-t-lg max-h-48 w-full object-cover" src={varmadryckerimg} alt="" />
  
    <div className="p-5">
      
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex flex-col">
            Varma Drycker</h5>
      
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Utforska värmande drycker som te, chokladpulver och chaipulver.</p>
        <div className="inline-flex items-center px-3 py-2 text-sm  font-medium text-center  underline underline-offset-2 text-slate-800 rounded-lg hover:text-varmadrycker focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-varmadrycker  dark:text-white dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Utforska Varma Drycker
             <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        
    </div>
</div>
</Link>
</div>


   </div>
   <div className='antipasto flex flex-col w-full	justify-start content-center text-center gap-4 p-3 self-start text-white'>
<div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  hover:shadow-xl shadow-md'">
<Link to="cat/dryck">
        <img className="rounded-t-lg max-h-48 w-full object-cover" src={dryckimg} alt="" />
  
    <div className="p-5">
      
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex flex-col">
            Dryck</h5>
      
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Här hittar du drycker för alla tillfällen: lemonad, juice, cider, tonicvatten och syrups.</p>
        <div className="inline-flex items-center px-3 py-2 text-sm  font-medium text-center underline underline-offset-2 text-slate-800 rounded-lg hover:text-dryck focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-dryck  dark:text-white dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Utforska Dryck
             <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        
    </div>
</div>
</Link>
</div>


   </div>
   <div className='antipasto flex flex-col w-full	justify-start content-center text-center gap-4 p-3 self-start text-white'>
<div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  hover:shadow-xl shadow-md'">
<Link to="cat/chips-snacks">
        <img className="rounded-t-lg max-h-48 w-full object-cover" src={chipssnacksimg} alt="" />
  
    <div className="p-5">
      
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex flex-col">
            Chips & Snacks</h5>
      
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Här hitar du läckra chips och gourmetpopcorn.</p>
        <div className="inline-flex items-center px-3 py-2 text-sm  font-medium text-center  underline underline-offset-2 text-slate-800 rounded-lg hover:text-chipssnacks focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-chipssnacks  dark:text-white dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Utforska Chips & Snacks
             <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        
    </div>
</div>
</Link>
</div>


   </div>
   <div className='antipasto flex flex-col w-full	justify-start content-center text-center gap-4 p-3 self-start text-white'>
<div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  hover:shadow-xl shadow-md'">
<Link to="cat/konfektyr">
        <img className="rounded-t-lg max-h-48 w-full object-cover" src={konfektyrimg} alt="" />
  
    <div className="p-5">
      
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex flex-col">
            Konfektyr</h5>
      
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Här hittar du varor som: choklad, fudge, konfekt, lakrits & mandlar.</p>
        <div className="inline-flex items-center px-3 py-2 text-sm  font-medium text-center  underline underline-offset-2 text-slate-800 rounded-lg hover:text-konfektyr focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600  dark:text-white dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Utforska Kex, bröd & Kakor
             <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        
    </div>
</div>
</Link>
</div>


   </div>
   <div className='antipasto flex flex-col w-full	justify-start content-center text-center gap-4 p-3 self-start text-white'>
<div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  hover:shadow-xl shadow-md'">
<Link to="cat/present">
        <img className="rounded-t-lg max-h-48 w-full object-cover" src={kexbrodkakorimg} alt="" />
  
    <div className="p-5">
      
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex flex-col">
            Present</h5>
      
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Färdiga presentset från alla kategorier och för alla tillfällen.</p>
        <div className="inline-flex items-center px-3 py-2 text-sm  font-medium text-center  underline underline-offset-2 text-slate-800 rounded-lg hover:text-kexbrodkakor focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600  dark:text-white dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Utforska Presenter
             <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        
    </div>
</div>
</Link>
</div>


   </div>
</div>
<div className="container-full mt-10 bg-gray-100 dark:bg-slate-900">
      <div className='container mx-auto p-8 text-center flex flex-col gap-4  dark:text-white text-slate-800'>
        <h2 className='text-2xl'>…Och mycket mer!</h2>
        <p className=''>Som kund hos GastronomiLeverantören har ni tillgång till hela sortimentet – inklusive alla nyheter, kampanjvaror, klippvaror, speciella sortiment & säsongsvaror med mera. 
          Bli återförsäljare idag och ta del av allt vi har att erbjuda!</p>
          
          <Link className='rounded-md bg-green-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-forestgreen-300 focus-visible:outline focus-visible:outline-2 w-auto m-auto focus-visible:outline-offset-2 focus-visible:outline-indigo-500' to="/bli-aterforsaljare">
          Bli återförsäljare</Link>
        
       
     </div>
      </div>
      <section className='container m-10 w-full  flex-col md:flex-row '>
        <h1 className='text-4xl  text-center font-semibold'>Hur vi arbetar:</h1>
        <p>Placeholder</p>
      </section>

      <section className='container m-10 w-full'>
<div className='flex flex-col md:flex-row '>
  <div className='showroom md:w-1/3 w-full text-center'>
  <img className='w-64 m-3 mx-auto' src={ShowroomGuide} alt="Showroomguiden" />
  <h1 className='text-xl font-bold'>Vi är med i showroomguiden!</h1>
  <p>Sugen på att komma att besöka oss?  </p>
  <a href="https://showroomsguiden.se" className='bg-green'>Läs mer!</a>

  </div>
  <div className='w-2/3'>
<InstagramFeed /></div>
      </div></section>
        </div>
    </div>
  );
};

export default Main;
